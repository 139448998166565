import { QuestionType, QuestionTypeInterface } from '../types'

export const questionType: QuestionTypeInterface = {
  multipleChoice: {
    id: 'multipleChoice',
    label: 'Multiple Choice',
  },
  checkboxes: {
    id: 'checkboxes',
    label: 'Checkboxes',
  },
  dropdown: {
    id: 'dropdown',
    label: 'Dropdown',
  },
  dropdownRange: {
    id: 'dropdownRange',
    label: 'DropdownRange',
  },
  dateSelect: {
    id: 'dateSelect',
    label: 'Date Select',
  },
  paragraph: {
    id: 'paragraph',
    label: 'Paragraph',
  },
  sortAnswer: {
    id: 'sortAnswer',
    label: 'Short answer',
  },
  fileCamera: {
    id: 'fileCamera',
    label: 'File Camera',
  },
  fileUpload: {
    id: 'fileUpload',
    label: 'File Upload',
  },
  bmi: {
    id: 'bmi',
    label: 'BMI',
  },
}

export const questionTypes: QuestionType[] = [
  {
    id: 'multipleChoice',
    label: 'Multiple Choice',
    isDefault: true,
  },
  {
    id: 'checkboxes',
    label: 'Checkboxes',
    isDefault: false,
  },
  {
    id: 'dropdown',
    label: 'Dropdown',
    isDefault: false,
  },
  {
    id: 'dropdownRange',
    label: 'DropdownRange',
    isDefault: false,
  },
  {
    id: 'dateSelect',
    label: 'Date Select',
    isDefault: false,
  },
  {
    id: 'paragraph',
    label: 'Paragraph',
    isDefault: false,
  },
  {
    id: 'sortAnswer',
    label: 'Short answer',
    isDefault: false,
  },
  {
    id: 'fileCamera',
    label: 'File Camera',
    isDefault: false,
  },
  {
    id: 'fileUpload',
    label: 'File Upload',
    isDefault: false,
  },
  {
    id: 'fileUpload',
    label: 'File Upload',
    isDefault: false,
  },
  {
    id: 'bmi',
    label: 'BMI',
    isDefault: false,
  },
]

export const defaultQuestionType = {
  id: 'multipleChoice',
  label: 'Multiple Choice',
  isDefault: true,
}
