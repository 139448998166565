import styled from 'styled-components'

export const ScrollContractStyle = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b7c5ce;
    border-radius: 9999px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b7c5ce;
  }
`
