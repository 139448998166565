export const SOCKET_ACTIONS = {
  NEW_RESERVATION: 'reservations/new',
  JOIN_EXAMINATION: 'medical-examinations/join',
  SELECT_PAYMENT: 'payments/select',
  SUCCESS_PAYPAY: 'paypay/success',
  ENTER_PHARMACY: 'pharmacies/enter',
}

export const actionPreventNotification = [
  SOCKET_ACTIONS.SUCCESS_PAYPAY,
  SOCKET_ACTIONS.ENTER_PHARMACY,
]
