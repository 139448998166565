import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

const initialState: any = {
  lastRunTime: localStorage.getItem('lastRunTime') || '',
}

export const lastRunTimeSlice = createSlice({
  name: 'lastRunTime',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLastRunTime: (state, action: PayloadAction<string>) => {
      state.lastRunTime = action.payload
      localStorage.setItem('lastRunTime', action.payload)
    },
  },
})

export const { setLastRunTime } = lastRunTimeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLastRunTime = (state: RootState) => state.time.lastRunTime

export default lastRunTimeSlice.reducer
