import styled from 'styled-components'

export const VideoOffCameraMain = styled.div`
  display: flex;
  justify-content: center;
  background: #333;
  height: 100%;
  color: #fff;
  border-radius: 5px;
  border: 8px solid #000;
  svg {
    width: 70%;
    align-self: center;
  }
`
export const RemoteVideoPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #333333;
  border-radius: 5px;
  border: 8px solid #000;
`
