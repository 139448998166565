import { forwardRef } from 'react'
import styled from 'styled-components'

export const SelectStyle = styled.div`
  position: relative;
  margin-top: 4px;
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
  }
  select::-ms-expand {
    display: none;
  }
`
interface FormSelectProps {
  options: any[]
  label?: string
  error?: any
  name?: string
  bgSelect?: string
  onChange?: (e: any) => void
  onHandleChange?: (e: any) => void
  checkDisableOption?: any
  disabled?: boolean
  className?: string
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const FormUISelect = forwardRef((props: FormSelectProps, ref) => {
  const {
    options,
    label,
    error,
    name,
    bgSelect,
    onChange,
    onHandleChange,
    checkDisableOption,
    disabled,
    className,
    ...restProps
  } = props

  const handleSelectedOptionChange = (e: any) => {
    const changeEvent = {
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    }
    onChange?.(changeEvent)
    onHandleChange?.(e)
  }
  const buttonClass = `border border-gray-300 px-3 py-2 pr-6 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 disabled:cursor-not-allowed disabled ${className}`

  return (
    <div className={`w-full mb-2 ${error ? 'is-invalid' : ''}`}>
      <label
        className="block font-semibold text-gray-710 text-sm"
        htmlFor={name}
      >
        {label}
      </label>
      <SelectStyle>
        <img
          className="absolute w-3 h-auto -translate-y-1/2 top-1/2 right-2"
          src="/arrow-down.png"
          alt=""
        />
        <select
          className={`${buttonClass} ${
            disabled ? 'bg-gray-80' : 'bg-[#FFFFFF]'
          }`}
          id={name}
          {...restProps}
          onChange={handleSelectedOptionChange}
        >
          {options &&
            options.map(
              (item: { value: string; label: string }, index: number) => (
                <option
                  value={item.value}
                  key={index}
                  disabled={
                    checkDisableOption !== 0
                      ? checkDisableOption % parseFloat(item?.value) !== 0
                      : false
                  }
                >
                  {item.label}
                </option>
              ),
            )}
        </select>
      </SelectStyle>
      <div className="feedback mt-1 text-sm text-red-700 min-h-[18px]">
        {error?.message}
      </div>
    </div>
  )
})

FormUISelect.defaultProps = {
  label: '',
  error: null,
  name: '',
  bgSelect: 'bg-gray-80',
  onChange: () => null,
  onHandleChange: () => null,
  checkDisableOption: 0,
  disabled: false,
  className: '',
}
