import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { TypeKeepImageRotated } from 'types/Patient'
import { updateRecords } from 'utils/helper'

type TypeInitialState = {
  keepImageRotated: TypeKeepImageRotated[]
}

const initialState: TypeInitialState = {
  keepImageRotated: [],
}

export const imageRotateSlice = createSlice({
  name: 'imageRotate',
  initialState,
  reducers: {
    setKeepImageRotated: (
      state,
      action: PayloadAction<TypeKeepImageRotated>,
    ) => {
      const newRecords: TypeKeepImageRotated[] = [action.payload]

      state.keepImageRotated = updateRecords(
        state.keepImageRotated,
        newRecords,
        (a, b) => a.id === b.id && a.type === b.type,
      )
    },
  },
})

export const { setKeepImageRotated } = imageRotateSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectKeepImageRotated = (state: RootState) =>
  state.imageRotate.keepImageRotated

export default imageRotateSlice.reducer
