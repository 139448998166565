import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export type MedicalRecordIdsUpdatedType = {
  id: string
  action: string
}

export interface CommonState {
  notiNewReversations: number[]
  medicalRecordIdsUpdated: MedicalRecordIdsUpdatedType[]
  scheduleRanges: string
  hasChangeStatus: boolean
}

const initialState: CommonState = {
  notiNewReversations: [],
  medicalRecordIdsUpdated: [],
  scheduleRanges: '',
  hasChangeStatus: false,
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addNotiNewReversation: (state, action: PayloadAction<any>) => {
      state.notiNewReversations = [...state.notiNewReversations, action.payload]
    },
    updateScheduleRanges: (state, action: PayloadAction<string>) => {
      state.scheduleRanges = action.payload
    },
    setMedicalRecordIdsUpdated: (
      state,
      action?: PayloadAction<MedicalRecordIdsUpdatedType[] | null>,
    ) => {
      if (action?.payload) {
        state.medicalRecordIdsUpdated = [
          ...state.medicalRecordIdsUpdated,
          ...action.payload,
        ]
      } else {
        state.medicalRecordIdsUpdated = []
      }
    },
    setHasChangeStatus: (state, action: PayloadAction<boolean>) => {
      state.hasChangeStatus = action.payload
    },
  },
})

export const {
  addNotiNewReversation,
  setMedicalRecordIdsUpdated,
  updateScheduleRanges,
  setHasChangeStatus,
} = commonSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.common.notiNewReversations)`
export const selectNotiNewReversations = (state: RootState) =>
  state.common.notiNewReversations

export const selectMedicalRecordIdsUpdated = (state: RootState) =>
  state.common.medicalRecordIdsUpdated

export const selectScheduleRanges = (state: RootState) =>
  state.common.scheduleRanges

export const selectHasChangeStatus = (state: RootState) =>
  state.common.hasChangeStatus

export default commonSlice.reducer
