import { Button } from 'app/components/Base'
import { QuestionnaireResponse } from 'app/containers/QuestionerTemplate/types'
import { patientHelper } from 'utils/patient'
import { table } from '../../config/table'

export function TableRow({
  questionnaireResponse,
  index,
  onShow,
}: {
  questionnaireResponse: QuestionnaireResponse
  index: number
  isLoading: boolean
  onShow: (item: QuestionnaireResponse) => void
}) {
  return (
    <tr className="text-left divide-x divide-gray-200">
      {table.columns.index.active && <td className="">{index + 1}</td>}
      <td className="px-3 py-4 text-sm text-gray-700 whitespace-nowrap">
        {questionnaireResponse.name}
      </td>
      <td className="px-3 py-4 text-[15px] text-[#333] whitespace-pre-line">
        {patientHelper.getFullName(questionnaireResponse.patient)}
      </td>
      <td className="px-3 py-4 text-[15px] text-[#333] whitespace-pre-line">
        {questionnaireResponse.createdAt}
      </td>
      <td className="px-3 py-4 text-[15px] text-[#333] whitespace-nowrap">
        <Button onClick={() => onShow(questionnaireResponse)}>Show</Button>
      </td>
    </tr>
  )
}
