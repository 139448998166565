import { ScrollContractStyle } from 'app/containers/PatientProfile/components/PatientHeader/components/StyleComponent'
import useClickOutSide from 'hooks/useClickOutSide'
import { useEffect, useRef, useState } from 'react'

interface FormDropDownProps {
  setValueDropdown: any
  isCancelMedical?: boolean
  valueDropdown: any
  optionDropdown: any
  maxHeightDropdown?: string
}

export function FormDropdown(props: FormDropDownProps) {
  const { setValueDropdown, isCancelMedical, valueDropdown, optionDropdown } =
    props

  const [label, setLabel] = useState<string>('')
  const { show, setShow, nodeRef } = useClickOutSide()
  const dropdownRef = useRef<any>(null)

  const borderClassBaseSelect =
    "before:content-[''] before:absolute before:top-full before:h-[1px] before:w-[calc(100%-16px)] before:ml-4 before:z-[2] before:left-0 before:bg-gray-second"

  const classBaseSelect = `relative flex items-center justify-between h-[41px] w-full py-[10px] px-3.5 text border border-[#D0D0D0] rounded-lg ${
    show
      ? `border-b-[#FFFFFF] rounded-b-none shadow-first ${borderClassBaseSelect}`
      : ''
  }}`
  const classBaseDropdown = `absolute top-full left-0 w-full overflow-y-auto overflow-x-hidden border border-[#D0D0D0] bg-white rounded-b-lg z-[1] ${
    show ? 'border-t-0' : 'invisible hidden'
  }`

  const findLabelByValue = (value: string) => {
    return optionDropdown.find((item: any) => item.value === value)?.label || ''
  }

  const handleButtonClick = () => {
    setShow(!show)
  }

  const handleClickItem = (item: string) => {
    setLabel(findLabelByValue(item))
    if (isCancelMedical) {
      setValueDropdown(item)
    } else setValueDropdown('permission', item)
    setShow(false)
  }

  useEffect(() => {
    if (valueDropdown) {
      handleClickItem(valueDropdown)
    } else {
      setLabel('')
    }
  }, [valueDropdown])

  return (
    <div className="relative w-full text-sm leading-[21px]" ref={nodeRef}>
      <div className={classBaseSelect} onClick={handleButtonClick}>
        <div className="w-full h-full whitespace-nowrap overflow-hidden text-ellipsis pr-6">
          {label}
        </div>
        <span
          onClick={e => {
            e.stopPropagation()
            handleButtonClick()
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="7"
            viewBox="0 0 9 7"
            fill="none"
          >
            <path
              d="M0.694461 0.699958H7.63891L4.16668 6.03329L0.694461 0.699958Z"
              fill="#6B7280"
            />
            <path
              d="M4.16667 5.36672L6.94444 1.10005H1.38889L4.16667 5.36672ZM4.16667 6.70005L0 0.300049H8.33333L4.16667 6.70005Z"
              fill="#6B7280"
            />
          </svg>
        </span>
      </div>
      <div className={classBaseDropdown} ref={dropdownRef}>
        <ScrollContractStyle className="pr-[1px] space-y-0.5 w-full overflow-y-auto font-light text-xs leading-[18px] max-h-[400px]">
          {optionDropdown.map((item: any) => (
            <div
              key={item.value}
              className="w-full py-[10px] pl-[14px] whitespace-nowrap overflow-hidden text-ellipsis pr-4 border-t-[1px] cursor-pointer"
              onClick={() => {
                handleClickItem(item.value)
              }}
            >
              {item.label}
            </div>
          ))}
        </ScrollContractStyle>
      </div>
    </div>
  )
}

FormDropdown.defaultProps = {
  isCancelMedical: false,
  maxHeightDropdown: '',
}
