export default function index() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.05 16.9125C10.35 16.6125 10.494 16.2499 10.482 15.8249C10.47 15.4 10.3135 15.0375 10.0125 14.7375L5.77499 10.5H22.5C22.925 10.5 23.2815 10.356 23.5695 10.068C23.8575 9.77995 24.001 9.42395 24 8.99995C24 8.57495 23.856 8.21845 23.568 7.93045C23.28 7.64245 22.924 7.49895 22.5 7.49995H5.77499L10.05 3.22495C10.35 2.92495 10.5 2.56845 10.5 2.15545C10.5 1.74245 10.35 1.38645 10.05 1.08745C9.74999 0.787451 9.39349 0.637451 8.9805 0.637451C8.5675 0.637451 8.21149 0.787451 7.91249 1.08745L1.05 7.94995C0.899996 8.09995 0.793493 8.26245 0.730494 8.43745C0.667494 8.61245 0.636494 8.79995 0.637493 8.99995C0.637493 9.19995 0.668995 9.38745 0.731995 9.56245C0.794996 9.73745 0.900995 9.89995 1.05 10.05L7.95 16.9499C8.22499 17.2249 8.56849 17.3625 8.9805 17.3625C9.3925 17.3625 9.74899 17.2125 10.05 16.9125Z"
        fill="#516CC3"
      />
    </svg>
  )
}
