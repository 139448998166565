import { toast } from 'react-toastify'

const DISPLAY_ERROR = true

const reporter = {
  error(error: any) {
    const message =
      error?.message ||
      error?.response?.data?.message ||
      'An error has occurred'
    // eslint-disable-next-line
    console.error(error)
    toast.error(
      DISPLAY_ERROR
        ? typeof message === 'string'
          ? message
          : JSON.stringify(message)
        : 'An error has occurred',
    )
  },
  success(success: any) {
    toast.success(success)
  },
}

export default reporter
