import { getFileImage, postFileImage } from 'app/services/file'
import { toast } from 'react-toastify'

export const fileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = error => {
      reject(error)
    }
  })
}

export const urlHeaders = {
  'Cache-Control': 'max-age=0',
}

export const fetchDataUrl = (
  singleUrl: string,
  headers: Record<string, string> = {},
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        // Append a timestamp to the base64 string to ensure uniqueness
        const uniqueBase64 = `${reader.result}#${Date.now()}`
        resolve(uniqueBase64)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.onerror = () =>
      reject(new Error(`Failed to fetch data from ${singleUrl}`))
    xhr.open('GET', singleUrl)
    // Set headers if any are provided
    Object.keys(headers).forEach(key => {
      xhr.setRequestHeader(key, headers[key])
    })
    xhr.responseType = 'blob'
    xhr.send()
  })
}

export const toDataUrl = async (
  url: string | string[],
  callback: any,
  headers: Record<string, string> = {},
) => {
  if (Array.isArray(url)) {
    await Promise.all(url.map(newUrl => fetchDataUrl(newUrl, headers)))
      .then(results => callback(results))
      // eslint-disable-next-line no-console
      .catch(error => console.error(error))
  } else {
    await fetchDataUrl(url, headers)
      .then(result => callback(result))
      // eslint-disable-next-line no-console
      .catch(error => console.error(error))
  }
}

export const base64ToFile = (
  dataUrl: string,
  filename: string,
): File | undefined => {
  const arr = dataUrl.split(',')
  if (arr.length < 2) {
    return undefined
  }

  const match = arr[0].match(/:(.*?);/)
  if (!match || match.length < 2) {
    return undefined
  }

  const mime = match[1]
  const byteString = atob(arr[1])
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uint8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; i += 1) {
    uint8Array[i] = byteString.charCodeAt(i)
  }

  const blob = new Blob([arrayBuffer], { type: mime })
  return new File([blob], filename, { type: mime })
}

export default async (binaryFile: string) => {
  let iconUrl = ''
  let iconName = ''
  try {
    const result = await postFileImage({ file: binaryFile })
    if (result && result.fileName) {
      iconName = result.fileName
      const dataGet = await getFileImage(result.fileName)
      iconUrl = dataGet.url
    }
  } catch (error) {
    toast.error('更新失敗！')
  }
  return { iconUrl, iconName }
}

export const handleSelectFileToBase64 = (imageArray: File[]) => {
  const promises = imageArray.map(image => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => {
        resolve(e.target?.result as string)
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsDataURL(image)
    })
  })
  return promises
}

export const checkRemainingUploadableImages = (imageArray: any[]) => {
  const fileRemain = imageArray.filter(
    image => !image?.name && !image?.url && !image?.file,
  )
  return fileRemain?.length || 0
}
