import { NO_ANSWER } from 'app/config/app'
import { ItemWithQuestion } from '../../types'

export function InputItemTextarea({
  item,
  index,
}: {
  item: ItemWithQuestion
  index: number
}) {
  const resolveValue = () => {
    const response =
      item.questionItem.question.textQuestion?.response || NO_ANSWER
    return response
  }

  return <span className={`index-${index}`}>{resolveValue()}</span>
}
