import { NO_ANSWER } from 'app/config/app'
import { ItemWithQuestionChoice } from '../../types'

export function InputItemCheckbox({
  item,
  index,
}: {
  item: ItemWithQuestionChoice
  index: number
}) {
  const options = item.questionItem.question.choiceQuestion?.options

  const resolveValue = () => {
    if (options && item.grid) {
      return options
        .filter(option => option.checked)
        .map(
          option =>
            `<span class="mr-4 last:mr-0 break-keep">${option.value}</span>`,
        )
        .join('')
    }
    return options
      .filter(option => option.checked)
      .map(option => `<div class="mb-1 last:mb-0">${option.value}</div>`)
      .join('')
  }
  if (options.filter(option => option.checked).length === 0) {
    return <span className={`index-${index}`}>{NO_ANSWER}</span>
  }

  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: resolveValue() }}
      className={`index-${index} ${
        options && item.grid && 'whitespace-pre-line space-y-1'
      } `}
    />
  )
}
