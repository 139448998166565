import { NO_ANSWER } from 'app/config/app'
import { Dispatch, SetStateAction } from 'react'
import { Item, ItemWithQuestion } from '../../types'

export function InputItemFileCamera({
  item,
  index,
  questionnaireResponseOriginId,
  setQuestionnaireResponseId,
  setItemQuestionnaireId,
  setOpenPopup,
  setIndexOrigin,
  setIndexImageCurrent,
  images,
}: {
  item: ItemWithQuestion
  index: number
  questionnaireResponseOriginId: string
  setQuestionnaireResponseId: Dispatch<SetStateAction<string>>
  setItemQuestionnaireId: (value: any) => void
  setOpenPopup: (value: boolean) => void
  setIndexOrigin: (value: number) => void
  setIndexImageCurrent: any
  formItems: Item[]
  images: string[]
}) {
  const resolveIndexValue = (urlImageValue: string) => {
    return images.findIndex((urlItem: string) => urlItem === urlImageValue)
  }

  const onClickImage = (url: string, mapIndex: number) => {
    setOpenPopup(true)
    setQuestionnaireResponseId(`${questionnaireResponseOriginId}${index}`)
    setItemQuestionnaireId(item.id)
    setIndexImageCurrent(resolveIndexValue(url))
    setIndexOrigin(mapIndex)
  }

  const arrayUrlImage =
    item?.questionItem?.question?.fileCameraQuestion?.response ?? []

  return (
    <span className={`index-${index} `}>
      {arrayUrlImage.length > 0 ? (
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          {arrayUrlImage.map((responseItem, mapIndex: number) => {
            return (
              <div
                key={responseItem.url}
                className={`${resolveIndexValue(responseItem.url)} ${mapIndex}`}
                onClick={() => onClickImage(responseItem.url, mapIndex)}
              >
                <img
                  className="rounded-[4px] w-[120px] h-[110px]"
                  key={mapIndex}
                  alt="upload1"
                  src={responseItem.url}
                />
              </div>
            )
          })}
        </div>
      ) : (
        NO_ANSWER
      )}
    </span>
  )
}
