import axios from 'axios'

const urlApi = process.env.REACT_APP_HT_AUTH_API_URL

export const authHTApi = {
  login: async (body: any) => {
    const response = await axios.post(`${urlApi}/public/user/login`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    return response.data
  },
  getUserAccessToken: async (body: any) => {
    const response = await axios.post(
      `${urlApi}/public/user/getUserAccessToken`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
    return response.data
  },
}
