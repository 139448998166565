export default function index() {
  return (
    <svg
      style={{
        height: '100%',
        width: '100%',
        padding: 10,
        boxSizing: 'border-box',
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#4C68C1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )
}
