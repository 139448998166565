import Tippy from '@tippyjs/react/headless'
import { ArrowDownDepartmentName } from '../TippyMenuDepartment/Style'

type Variant = '2xl' | '3xl' | '4xl'
interface DepartmentProps {
  name: string
  size: Variant
  iconUrl?: string
  isActive?: boolean
  className?: string
  onClick?: any
}

export default function Department(props: DepartmentProps) {
  const { name, size, iconUrl, isActive, className, onClick } = props

  const showNameChecked = (value: string) => {
    const fullWidthRegex =
      /[\uFF01-\uFF5E\uFFE0-\uFFE6\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\u3200-\u32FF\u3300-\u33FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]/g
    return value && fullWidthRegex.test(value)
      ? value?.slice(0, 2)
      : value?.slice(0, 3)
  }

  const checkIconFontSize = (fontSize: string) => {
    return !iconUrl ? fontSize : ''
  }

  const checkFontSize4Xl = `${
    showNameChecked(name)?.length === 2 ? 'text-[40px]' : 'text-[35px]'
  } leading-[48px]`

  const sizes: Record<Variant, string> = {
    '2xl': `w-6 h-6  rounded-[32px] ${checkIconFontSize(
      'text-[8px] leading-[10px]',
    )}`,
    '3xl': `w-8 h-8 rounded-[32px] ${checkIconFontSize(
      'text-[10px] leading-3',
    )}`,
    '4xl': `w-28 h-28 rounded-full ${checkIconFontSize(checkFontSize4Xl)}`,
  }

  const classBase = `flex items-center justify-center bg-[#3772D0] font-semibold text-white ${sizes[size]} ${className}`
  const classIconUrl = `object-cover ${sizes[size]}`
  const classBaseAction = 'p-1 select-none cursor-pointer rounded-[32px]'
  const classActive = `bg-white shadow-[0px_0px_0px_2px_#3772D0] ${classBaseAction}`

  const classNoActive = `bg-white shadow-[0px_0px_0px_1px_#D0D0D0] ${classBaseAction}`

  const shouldActivated = () => {
    if (onClick) {
      return isActive ? classActive : classNoActive
    }
    return iconUrl && 'flex-shrink-0'
  }

  const checkClassBase = () => {
    return iconUrl ? className : classBase
  }

  const showIconActive = () => {
    return (
      onClick &&
      isActive && (
        <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 5.59091L5.94118 10.5L15 1.5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )
    )
  }

  const renderTippyResult = (nameDepartment: string, attrs: any) => {
    return (
      <>
        <span
          className="p-2 text-xs leading-none whitespace-nowrap bg-[#3772D0] rounded-md shadow-lg text-white"
          {...attrs}
        >
          {nameDepartment}
        </span>
        <div data-tippy-arrow>
          <ArrowDownDepartmentName />
        </div>
      </>
    )
  }

  const popperOptions = {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: '[data-tippy-arrow]',
        },
      },
    ],
  }

  return (
    <Tippy
      interactive
      zIndex={99}
      placement="top"
      popperOptions={popperOptions}
      render={attrs => {
        if (onClick) {
          return renderTippyResult(name, attrs)
        }
      }}
    >
      <div className={shouldActivated()} onClick={onClick}>
        <span className={`relative ${checkClassBase()}`}>
          {iconUrl ? (
            <img src={iconUrl} className={classIconUrl} alt="" />
          ) : (
            showNameChecked(name)
          )}
          {showIconActive()}
        </span>
      </div>
    </Tippy>
  )
}

Department.defaultProps = {
  iconUrl: '',
  className: '',
  isActive: false,
  onClick: null,
}
