import { apiClient } from './api'

export const getDummyMeetingJoining = async () => {
  const dummyMeetingJoining: any = await apiClient.get<any>(
    'api/v1/medical-examinations/camera-check',
    {},
  )
  return dummyMeetingJoining
}

export const getMeetingJoining = async (
  medicalRecordId: string,
  params: any,
) => {
  const response: any = await apiClient.get<any>(
    `api/v1/medical-examinations/${medicalRecordId}/room`,
    { params },
  )
  return response
}

export const callPatient = async (medicalRecordId: string) => {
  const response: any = await apiClient.post<any>(
    `api/v1/medical-examinations/${medicalRecordId}/call`,
    {},
  )
  return response
}

export const terminateMedicalExamination = async (
  medicalRecordId: string,
  data: { reason: 'NO_RESPONSE' | 'COMPLETE' },
) => {
  const response: any = await apiClient.post<any>(
    `api/v1/medical-examinations/${medicalRecordId}/terminate`,
    data,
  )
  return response
}

export const endMedicalExamination = async (medicalRecordId: string) => {
  const response: any = await apiClient.post<any>(
    `api/v1/medical-examinations/${medicalRecordId}/end-consultation`,
    {},
  )
  return response
}

export const sendMessageToDoctor = async (
  medicalRecordId: string,
  data: object,
) => {
  const response: any = await apiClient.post<any>(
    `api/v1/medical-examinations/${medicalRecordId}/delay`,
    data,
  )
  return response
}
