import { PopupZoomRotateImage } from 'app/components/PopupZoomRotateImage/PopupZoomRotateImage'
import { STORAGE_DOCUMENT_PATHS_TYPES } from 'app/config/app'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  selectListImageQuestionnaire,
  setListImageQuestionnaire,
} from 'features/tabReducer/tabReducerSlice'
import { useEffect, useState } from 'react'
import { questionType } from '../constants'
import {
  Item,
  ItemWithQuestion,
  ItemWithQuestionBMI,
  ItemWithQuestionChoice,
  ItemWithQuestionSelectDate,
} from '../types'
import { handleLoadInputTempFiles } from '../utils/api'
import { InputItemBMI } from './InputItemBMI'
import { InputItemCheckbox } from './InputItemCheckbox'
import { InputItemChoice } from './InputItemChoice'
import { InputItemDropdown } from './InputItemDropdown'
import { InputItemDropdownRange } from './InputItemDropdownRange'
import { InputItemFileCamera } from './InputItemFileCamera'
import { InputItemSelectDate } from './InputItemSelectDate'
import { InputItemTextarea } from './InputItemTextarea'

interface QuestionerSubmissionProps {
  formItems: Item[]
  id?: string
}

export function QuestionerSubmission({
  formItems,
  id,
}: QuestionerSubmissionProps) {
  const dispatch = useAppDispatch()
  const listImageQuestionnaire = useAppSelector(selectListImageQuestionnaire)

  const [formResponseItems, setFormResponseItems] = useState<Item[]>([])
  const [itemQuestionnaireId, setItemQuestionnaireId] = useState<any>('')
  const [questionnaireResponseId, setQuestionnaireResponseId] =
    useState<string>('')
  const [openPopup, setOpenPopup] = useState(false)
  const [indexImageCurrent, setIndexImageCurrent] = useState<number>()
  const [indexOriginImage, setIndexOrigin] = useState<number>()

  useEffect(() => {
    setQuestionnaireResponseId(id as string)
  }, [id])

  const valueImageQuestionnaire = (key: string, itemId: string) => {
    return listImageQuestionnaire?.find(
      item => item.key === key && item.itemId === itemId,
    )?.value
  }

  const handleLoadFormResponseStorage = async (dataItems: Item[]) => {
    const payloadItems = JSON.parse(JSON.stringify(dataItems))

    for (let i = 0; i < payloadItems.length; i += 1) {
      const payloadItem = payloadItems[i]
      if (
        payloadItem.questionItem?.question.kind === questionType.fileCamera.id
      ) {
        const infoImage = payloadItem.questionItem?.question.fileCameraQuestion
        if (infoImage) {
          const key = (id as string) + i
          const itemId = payloadItem?.id
          const valueOfQuestionnaire = valueImageQuestionnaire(key, itemId)

          if (!valueOfQuestionnaire) {
            // eslint-disable-next-line no-await-in-loop
            const urlImage = await handleLoadInputTempFiles(infoImage)

            payloadItem.questionItem.question.fileCameraQuestion = urlImage

            if (id && urlImage?.response?.length) {
              dispatch(
                setListImageQuestionnaire({
                  key,
                  itemId,
                  value: urlImage,
                }),
              )
            }
          } else {
            payloadItem.questionItem.question.fileCameraQuestion =
              valueOfQuestionnaire
          }
        }
      }
    }

    return payloadItems
  }
  // const arrayImage: any = ['健診結果等（未提出の場合）']

  const handleParseItems = async () => {
    const newItems: Item[] = await handleLoadFormResponseStorage(formItems)
    setFormResponseItems(newItems)
  }

  useEffect(() => {
    handleParseItems()
  }, [formItems])

  const resolveClassName2 = (item: Item) => {
    if (item.linkedSection) {
      const kindArray: any[] = [
        questionType.multipleChoice.id,
        questionType.dropdown.id,
        questionType.dropdownRange.id,
        questionType.dateSelect.id,
        questionType.paragraph.id,
        questionType.sortAnswer.id,
        questionType.fileCamera.id,
      ]

      if (kindArray.includes(item?.questionItem?.question.kind)) {
        // find children item
        const linkedSectionIndex = formItems.findIndex(
          formItem => formItem.id === (item.linkedSection as any).linkedItem.id,
        )
        const linkedSection = formItems[linkedSectionIndex]
        const selectedOptionValueCheckboxes: any =
          linkedSection?.questionItem?.question.choiceQuestion?.options
            .filter(option => option.checked)
            .map(optionItem => optionItem.value)
        const linkedSectionValue =
          linkedSection?.questionItem?.question.choiceQuestion?.value
        const itemLinkedSectionValue = item.linkedSection?.value

        if (linkedSectionValue) {
          return linkedSectionValue !== itemLinkedSectionValue
            ? 'hidden'
            : 'py-1'
        }
        return !selectedOptionValueCheckboxes?.includes(itemLinkedSectionValue)
          ? 'hidden'
          : 'py-1'
      }
      return
    }
    if (item.inGroup) {
      return 'pt-1'
    }
    return 'pt-4'
  }

  const getQuestionnaireResponse = () => {
    const filterQuestionnaires = listImageQuestionnaire.filter(
      list => list.key.slice(0, (id as string).length) === id,
    )
    const questionnaireResponses = filterQuestionnaires.map(
      item => item?.value?.response,
    )

    return questionnaireResponses || []
  }

  const resolveListImage = (formResponseList: Item[]) => {
    const responseUrls = getQuestionnaireResponse()
      .flat(1)
      .map((item: any) => item?.url)

    if (responseUrls.length > 0) {
      return responseUrls
    }

    return formResponseList
      .map(
        obj => obj.questionItem?.question?.fileCameraQuestion?.response || [],
      )
      .reduce((acc, files) => {
        const urls = files.map(file => file.url).filter(Boolean)
        return acc.concat(urls)
      }, [] as string[])
  }

  const resolveFormItem = (item: Item, index: number) => {
    return (
      <>
        {item.questionItem?.question.kind ===
          questionType.multipleChoice.id && (
          <InputItemChoice
            item={item as ItemWithQuestionChoice}
            index={index}
          />
        )}
        {item.questionItem?.question.kind === questionType.checkboxes.id && (
          <InputItemCheckbox
            item={item as ItemWithQuestionChoice}
            index={index}
          />
        )}
        {item.questionItem?.question.kind === questionType.dropdown.id && (
          <InputItemDropdown
            item={item as ItemWithQuestionChoice}
            index={index}
          />
        )}
        {item.questionItem?.question.kind === questionType.dropdownRange.id && (
          <InputItemDropdownRange
            item={item as ItemWithQuestionChoice}
            index={index}
          />
        )}
        {item.questionItem?.question.kind === questionType.sortAnswer.id && (
          <InputItemTextarea item={item as ItemWithQuestion} index={index} />
        )}
        {item.questionItem?.question.kind === questionType.paragraph.id && (
          <InputItemTextarea item={item as ItemWithQuestion} index={index} />
        )}
        {item.questionItem?.question.kind === questionType.fileCamera.id && (
          <InputItemFileCamera
            item={item as ItemWithQuestion}
            index={index}
            questionnaireResponseOriginId={id as string}
            setOpenPopup={setOpenPopup}
            setQuestionnaireResponseId={setQuestionnaireResponseId}
            setItemQuestionnaireId={setItemQuestionnaireId}
            setIndexOrigin={setIndexOrigin}
            setIndexImageCurrent={setIndexImageCurrent}
            formItems={formItems}
            images={resolveListImage(formResponseItems)}
          />
        )}
        {item.questionItem?.question.kind === questionType.dateSelect.id && (
          <InputItemSelectDate
            item={item as ItemWithQuestionSelectDate}
            index={index}
          />
        )}
        {item.questionItem?.question.kind === questionType.bmi.id && (
          <InputItemBMI item={item as ItemWithQuestionBMI} index={index} />
        )}
      </>
    )
  }

  const resolveLayoutItem = (item: Item, index: number) => {
    const classBaseLabel = `text-[14px] leading-[17px] inline-block text-[#3772D0] mr-1 font-light ${
      !item?.linkedSection && 'mb-1'
    }`
    const classBaseContent =
      'text-[14px] leading-[17px] break-words text-gray-710 font-light'

    function WrapperForItem({
      className,
      children,
    }: {
      className: string
      children: React.ReactNode
    }) {
      return item?.linkedSection || item?.inline ? (
        <span className={className}>{children}</span>
      ) : (
        <div className={className}>{children}</div>
      )
    }

    return (
      <>
        <WrapperForItem className={classBaseLabel}>
          {item?.linkedSection && '・'}
          {item.title} {(item?.linkedSection || item?.inline) && ':'}
        </WrapperForItem>
        <WrapperForItem className={classBaseContent}>
          {resolveFormItem(item, index)}
        </WrapperForItem>
      </>
    )
  }

  useEffect(() => {
    resolveListImage(formResponseItems)
  }, [formResponseItems])

  useEffect(() => {
    const newValueImageQuestionnaire = listImageQuestionnaire?.find(
      item => item.key === questionnaireResponseId,
    )?.value

    const lengthQuestionResponseId = (id as string)?.length

    if (lengthQuestionResponseId) {
      const newQuestionnaireResponseId = +questionnaireResponseId.slice(
        lengthQuestionResponseId,
      )

      if (
        newQuestionnaireResponseId &&
        newQuestionnaireResponseId > -1 &&
        newValueImageQuestionnaire &&
        formResponseItems[newQuestionnaireResponseId].questionItem?.question
          .fileCameraQuestion
      ) {
        ;(
          formResponseItems[newQuestionnaireResponseId] as any
        ).questionItem.question.fileCameraQuestion = newValueImageQuestionnaire

        setFormResponseItems(formResponseItems)
      }
    }
  }, [listImageQuestionnaire])

  return (
    <div className="relative text-[12px] overflow-hidden  w-full bg-white whitespace-pre-line duration-1000 h-auto break-all font-light">
      {formResponseItems.map((item, index: number) => (
        <div key={index} className={resolveClassName2(item)}>
          {resolveLayoutItem(item, index)}
        </div>
      ))}
      {resolveListImage(formResponseItems) && (
        <PopupZoomRotateImage
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          popupId={id}
          setQuestionnaireResponseId={setQuestionnaireResponseId}
          setItemQuestionnaireId={setItemQuestionnaireId}
          itemQuestionnaireId={itemQuestionnaireId}
          image={resolveListImage(formResponseItems)}
          questionnaireResponseId={questionnaireResponseId}
          type={STORAGE_DOCUMENT_PATHS_TYPES.otherDocumentQuestionnaire}
          indexOriginImage={indexOriginImage}
          indexImageCurrent={indexImageCurrent}
          setIndexOrigin={setIndexOrigin}
          setIndexImageCurrent={setIndexImageCurrent}
          alt="medicine book picture"
        />
      )}
    </div>
  )
}

QuestionerSubmission.defaultProps = {
  id: '',
}
