/**
 * Asynchronously loads the component for QuestionerForm
 */

import React from 'react'
import { lazyLoad } from 'utils/loadable'
import { LoadingWrapper } from 'app/components/LoadingWrapper'
import { LoadingIndicator } from 'app/components/LoadingIndicator'

export const QuestionerForm = lazyLoad(
  () => import('./index'),
  module => module.QuestionerForm,
  {
    fallback: (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    ),
  },
)
