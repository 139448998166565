import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

export type DeliveryPharmacyType = {
  address: string
  fax: string
  name: string
  tel: string
}

type InitialStateProps = {
  isClickEditButton: boolean
  isKeydownFee: boolean
  isEditFeeScreen: boolean
  deliveryPharmacy: DeliveryPharmacyType
}

const initialState: InitialStateProps = {
  isClickEditButton: false,
  isKeydownFee: false,
  isEditFeeScreen: false,
  deliveryPharmacy: {
    address: '',
    fax: '',
    name: '',
    tel: '',
  },
}

export const patientPageSlice = createSlice({
  name: 'patientPage',
  initialState,
  reducers: {
    setIsClickEditButton: (state, action: PayloadAction<boolean>) => {
      state.isClickEditButton = action.payload
    },
    setIsKeydownFee: (state, action: PayloadAction<boolean>) => {
      state.isKeydownFee = action.payload
    },
    setIsEditFeeScreen: (state, action: PayloadAction<boolean>) => {
      state.isEditFeeScreen = action.payload
    },
    resetInitialStatePatientPage: state => {
      state.isClickEditButton = false
      state.isKeydownFee = false
      // state.isEditFeeScreen = false
    },
    setDeliveryPharmacy: (
      state,
      action: PayloadAction<DeliveryPharmacyType>,
    ) => {
      state.deliveryPharmacy = { ...state.deliveryPharmacy, ...action.payload }
    },
  },
})

export const {
  setIsClickEditButton,
  setIsKeydownFee,
  resetInitialStatePatientPage,
  setIsEditFeeScreen,
  setDeliveryPharmacy,
} = patientPageSlice.actions

export const selectTab = (state: RootState) => state.switch_tab.value

export default patientPageSlice.reducer
