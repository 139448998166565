import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef } from 'react'

type ModalMainSize = 'small' | 'medium' | 'large'

interface ModalMainProps {
  open?: boolean
  title?: string
  onClose: (value: boolean) => void
  size?: ModalMainSize
  className?: string
  children: any
}

export function ModalMain(props: ModalMainProps) {
  const { title, size, open, onClose, children, className } = props
  const cancelButtonRef = useRef(null)

  const resolveClassName = () => {
    const baseClassList = [
      'relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:p-6',
    ]
    if (size === 'small') {
      baseClassList.push('sm:max-w-lg sm:w-[400px]')
    }
    if (size === 'medium') {
      baseClassList.push('sm:max-w-lg sm:w-[700px]')
    }
    if (size === 'large') {
      baseClassList.push('md:w-[800px] md:w-[900px]')
    }
    return baseClassList.join(' ')
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={(className ?? '').concat(' relative z-10 dialog')}
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={resolveClassName()}>
                {title && (
                  <div className="mt-3 text-center sm:mt-5 modal-content">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl font-bold text-center mb-2 leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                )}
                <div className="modal-content">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

ModalMain.defaultProps = {
  open: false,
  title: '',
  size: 'medium',
  className: '',
}
