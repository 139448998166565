/* eslint-disable jsx-a11y/media-has-caption */
import React, { forwardRef } from 'react'
import { useLocalVideo } from 'amazon-chime-sdk-component-library-react'
import { Camera } from 'app/components/Meeting/ui/icons/Camera'
import { VideoOffCameraMain, LocalVideoWrapper } from './Style'
import { VideoTileProps } from '../../Types'

export const VideoTile = forwardRef(
  (props: VideoTileProps, ref: React.Ref<HTMLVideoElement>) => {
    const { isVideoEnabled } = useLocalVideo()
    const featured = false

    const classes = `${featured ? 'ch-video ch-featured-tile' : 'ch-video'}`

    return (
      <LocalVideoWrapper className="x-remote-video">
        {isVideoEnabled ? (
          <video ref={ref} className={classes} />
        ) : (
          <VideoOffCameraMain className="remote-video-off">
            <Camera width="5rem" disabled />{' '}
          </VideoOffCameraMain>
        )}
      </LocalVideoWrapper>
    )
  },
)
