import { useMemo, useEffect } from 'react'

interface AudioProps {
  url: string
  playing: boolean
  setPlaying: (status: boolean) => void
  options?: {
    loop: boolean
  }
}

const useAudio = (params: AudioProps) => {
  const { url, playing, setPlaying, options = { loop: false } } = params
  const audio = useMemo(() => new Audio(url), [])

  const toggle = () => setPlaying(!playing)

  useEffect(() => {
    if (playing) {
      audio.play()
      if (options.loop) audio.loop = true
    } else {
      audio.pause()
    }
  }, [playing])

  useEffect(() => {
    if (!options.loop) {
      audio.addEventListener('ended', () => setPlaying(false))
    }
    return () => {
      if (!options.loop) {
        audio.removeEventListener('ended', () => setPlaying(false))
      }
    }
  }, [])

  return [playing, toggle]
}

export default useAudio
