/* eslint-disable prefer-destructuring */
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, InputControl } from 'app/components/Base'
import { FormDropdown } from 'app/components/Base/FormDropdown'
import Department from 'app/components/Common/Department'
import { PopupConfirm } from 'app/components/PopupConfirm'
import {
  CURRENT_PAGE,
  IMAGE_SIZE_EXCEEDS,
  PERMISSION_OPTIONS,
  STORAGE_TARGET_PATHS_TYPES,
} from 'app/config/app'
import { ROUTES } from 'app/config/routes'
import { getDepartments } from 'app/services/departments'
import {
  postGeneratePresignedUrl,
  putGeneratePresignedUrl,
} from 'app/services/file'
import staffService from 'app/services/staff'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Department as TypeDepartment } from 'types/Department'
import { sortOrderDepartmentByCategory } from 'utils/department'
import {
  checkMaxSizeUploadFileImage,
  convertFullToHalf,
  formatPhoneNumberToDash,
  removeSpecialCharacters,
} from 'utils/helper'
import { fileToBase64 } from 'utils/image'
import reporter from 'utils/reporter'
import { createSchema, updateSchema } from '../config/validation'

/**
 * Screen: OMM-021
 */

type ImageType = 'profilePicture' | 'hpkiPicture'
interface FocusedPassword {
  isFocusedPassword: boolean
  isFocusedConfirmPassword: boolean
}

export function UpdateOrCreateStaff() {
  const { t } = useTranslation()
  const { staffId } = useParams<{ staffId: string }>()
  const [apiErrors, setApiErrors] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [staffColor, setStaffColor] = useState<string>('#4ca76f')
  const bgColor = '002EA8'
  const TEL_REGEX = /^0\d{9,10}$/
  const initialValue = {
    id: '',
    name: '',
    email: '',
    nameKana: '',
    permission: staffId ? '' : 'doctor',
    sections: [] as string[],
    password: '',
    passwordConfirmation: '',
    profilePicture: undefined,
    certificatePicture: undefined,
    tel: '',
    color: staffColor,
  }

  const [staff, setStaff] = useState<typeof initialValue>(initialValue)
  const [isFetchingStaff, setFetchingStaff] = useState<boolean>(true)

  const [selectedSections, setSelectedSections] = useState<string[]>([])

  const [profilePicture, setProfilePicture] = useState('')

  const [hpkiPicture, setHpkiPicture] = useState('')
  const [openPopupConfirmDelete, setOpenPopupConfirmDelete] =
    useState<boolean>(false)

  const [valueTel, setValueTel] = useState<string>('')
  const [departments, setDepartments] = useState<TypeDepartment[]>([])
  const [isFocused, setIsFocused] = useState<FocusedPassword>({
    isFocusedPassword: false,
    isFocusedConfirmPassword: false,
  })
  const [isShowErrorUploadProFile, setIsShowErrorUploadProFile] =
    useState<boolean>(false)
  const [isShowErrorUploadHpkiPicture, setIsShowErrorUploadHpkiPicture] =
    useState<boolean>(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    setSelectedSections(staff.sections)
  }, [staff.sections])

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(staffId ? updateSchema : createSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialValue,
  })

  useEffect(() => {
    const subscription = watch(() => setApiErrors({}))
    return () => subscription.unsubscribe()
  }, [watch])

  const handleChangeSection = (section: string) => {
    const indexOf = selectedSections.indexOf(section)
    const newSections = [...selectedSections]
    if (indexOf === -1) {
      newSections.push(section)
    } else {
      newSections.splice(indexOf, 1)
    }
    setValue('sections', newSections)
    setSelectedSections(newSections)
  }

  const handleChangeColor = (e: any) => {
    setStaffColor(e.target.value)
  }

  const handleUploadImage = async (fileType: string) => {
    if (!fileType) return
    const data = await postGeneratePresignedUrl(
      STORAGE_TARGET_PATHS_TYPES.staffFilePath,
      { fileType },
    )
    return data
  }

  const handleChangeImage = async (e: any, type: ImageType) => {
    if (e.target.files.length < 1) {
      return
    }
    const file = e.target.files[0]
    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg'
    ) {
      toast.error('.jpg、.png ファイルのみが受け入れられます')
      e.target.value = ''
    } else {
      const imageBase64: any = await fileToBase64(file)
      if (type === 'profilePicture') {
        if (checkMaxSizeUploadFileImage(file)) {
          setIsShowErrorUploadProFile(true)
          if (!profilePicture) {
            setProfilePicture('')
            setValue('profilePicture', undefined)
          }
        } else {
          setIsShowErrorUploadProFile(false)
          setValue('profilePicture', file)
          setProfilePicture(imageBase64)
        }
      }
      if (type === 'hpkiPicture') {
        if (checkMaxSizeUploadFileImage(file)) {
          setIsShowErrorUploadHpkiPicture(true)
          if (!hpkiPicture) {
            setHpkiPicture('')
            setValue('certificatePicture', undefined)
          }
        } else {
          setIsShowErrorUploadHpkiPicture(false)
          setValue('certificatePicture', file)
          setHpkiPicture(imageBase64)
          e.target.value = ''
        }
      }
    }
  }

  const handleDeleteHpkiPicture = () => {
    setValue('certificatePicture', undefined)
    setHpkiPicture('')
    setOpenPopupConfirmDelete(false)
    setIsShowErrorUploadHpkiPicture(false)
  }

  useEffect(() => {
    ;(async () => {
      const response: TypeDepartment[] = await getDepartments()
      setDepartments(sortOrderDepartmentByCategory(response) as any)

      if (!staffId) return
      setFetchingStaff(true)
      try {
        const data: any = await staffService.getInfoStaffV2(staffId)
        data.tel = removeSpecialCharacters(data.tel)
        setStaff(data)
        setProfilePicture(data?.profilePictureUrl)
        setHpkiPicture(data?.certificatePictureUrl)
        setStaffColor(data?.color)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      } finally {
        setFetchingStaff(false)
      }
    })()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    reset(staff)
    setValueTel(watch('tel'))
    // eslint-disable-next-line
  }, [staff])

  const handleFormSubmit = async (data: any) => {
    let isErrUpload = false
    setIsLoading(true)
    setApiErrors({})

    const payload = {
      ...data,
    }

    const fileProfilePicture = payload?.profilePicture
    const fileCertificatePicture = payload?.certificatePicture

    const promisePutUrl: Promise<any>[] = []

    const getPresignedUrl = [
      fileProfilePicture ? handleUploadImage(fileProfilePicture?.type) : {},
      fileCertificatePicture
        ? handleUploadImage(fileCertificatePicture?.type)
        : {},
    ]

    await Promise.all(getPresignedUrl)
      .then((result: any[]) => {
        payload.profilePictureId = result[0].fileName
        payload.certificatePictureId = result[1].fileName

        if (fileProfilePicture && result[0]?.url) {
          promisePutUrl.push(
            putGeneratePresignedUrl(result[0].url, fileProfilePicture),
          )
        }

        if (fileCertificatePicture && result[1]?.url) {
          promisePutUrl.push(
            putGeneratePresignedUrl(result[1].url, fileCertificatePicture),
          )
        }
      })
      .catch(() => {
        isErrUpload = true
        setIsLoading(false)
        reporter.error({ message: IMAGE_SIZE_EXCEEDS })
      })

    await Promise.all(promisePutUrl)

    delete payload?.profilePicture
    delete payload?.certificatePicture

    if (isErrUpload) return

    // If hpkiPicture is empty, remove the preceding URL.
    if (!hpkiPicture) {
      payload.certificatePictureUrl = ''
    }

    payload.permission = getValues('permission')
    if (!data.password) {
      delete data.password
    }
    payload.tel = formatPhoneNumberToDash(data.tel)

    if (staffId) {
      staffService
        .updateStaffV2(staffId, payload)
        .then(() => {
          toast.success('スタッフは正常に更新されました!')
          setTimeout(() => {
            return navigate(ROUTES.STAFF.INDEX)
          }, 1000)
        })
        .catch(error => {
          setApiErrors(error?.response?.data?.stack?.data)
        })
        .finally(() => setIsLoading(false))
    } else {
      staffService
        .createStaffV2(payload)
        .then(() => {
          toast.success('スタッフが正常に作成されました!')
          setTimeout(() => {
            return navigate(ROUTES.STAFF.INDEX)
          }, 1000)
          localStorage.setItem(CURRENT_PAGE, JSON.stringify(1))
        })
        .catch(error => {
          setApiErrors(error?.response?.data?.stack?.data)
        })
        .finally(() => setIsLoading(false))
    }
  }

  useEffect(() => {
    if (watch('tel').match(TEL_REGEX)?.input) {
      clearErrors('tel')
    }
  }, [watch('tel')])

  const handleOnChangeTel = (event: any) => {
    const maxLength = 11
    const telNumber = convertFullToHalf(event.target.value)
    if (getValues('tel').length <= maxLength && telNumber.length <= maxLength) {
      setValue('tel', telNumber)
      setValueTel(event.target.value)
    }
  }

  const handleOnBlurTel = () => {
    setValue('tel', convertFullToHalf(valueTel))
    setValueTel(convertFullToHalf(valueTel))
  }

  const isValidationPasswordUpdateStaff = () =>
    staffId ? true : !!watch('passwordConfirmation')

  const handleFocusPassword = (
    type: 'isFocusedPassword' | 'isFocusedConfirmPassword',
    isFocusedField: boolean,
  ) => {
    setIsFocused(prevIsFocused => ({
      ...prevIsFocused,
      [type]: isFocusedField,
    }))
    if (!isFocusedField && isValidationPasswordUpdateStaff()) {
      if (watch('passwordConfirmation') === watch('password')) {
        clearErrors('passwordConfirmation')
      } else
        setError('passwordConfirmation', {
          type: 'custom',
          message: 'パスワードが一致しません',
        })
    }
  }

  const isShowPlaceHolder = (isBlur: boolean): string =>
    isFetchingStaff ? '' : isBlur && staffId ? '•••••••••••••' : ''

  const inputPasswordProps = (
    fieldFocused: 'isFocusedPassword' | 'isFocusedConfirmPassword',
    isFocus: boolean,
    name: 'password' | 'passwordConfirmation',
  ) => {
    const onFocus = () => handleFocusPassword(fieldFocused, true)
    const onBlur = () => handleFocusPassword(fieldFocused, false)

    return {
      control,
      name,
      onFocus,
      onBlur,
      placeholder: isShowPlaceHolder(!isFocus),
      type: !isFocus && watch(name) ? 'password' : 'text',
      fontSizeLabel: 'text-sm leading-[21px]',
      classWrapper: 'mt-1',
      customBorder: 'placeholder-[#333] rounded-lg',
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('pages.CreateStaff.title')}</title>
        <meta name="description" content="APP_NAME App" />
      </Helmet>

      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
        <div className="flex items-center justify-between mb-[21px]">
          <span className="text-lg leading-[27px]">
            <p className="text-gray-710 font-semibold">
              {t('labels.Staffs.staff_manager')}
            </p>
          </span>
          <div>
            <Button
              className="py-1 text-base px-14"
              color={bgColor}
              type="submit"
              loading={isLoading}
            >
              {t('labels.Staffs.save')}
            </Button>
          </div>
        </div>
        <div className="h-fit bg-[#FFFFFF] border border-gray-110 p-6">
          <div className="flex w-full gap-x-10">
            <div className="basis-1/3">
              <div className="mb-6">
                <InputControl
                  control={control}
                  name="name"
                  maxLength={60}
                  type="text"
                  customBorder="rounded-lg"
                  fontSizeLabel="text-sm leading-[21px]"
                  classWrapper="mt-1"
                  label={`${t('labels.Staffs.name')}*`}
                />
              </div>
              <div className="mb-6">
                <InputControl
                  control={control}
                  name="nameKana"
                  maxLength={60}
                  customBorder="rounded-lg"
                  type="text"
                  fontSizeLabel="text-sm leading-[21px]"
                  classWrapper="mt-1"
                  label={`${t('labels.Staffs.furigana')}*`}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm leading-[21px] font-semibold text-gray-710 mb-1">
                  {`${t('labels.Staffs.authorization')}*`}
                </label>
                <FormDropdown
                  valueDropdown={watch('permission')}
                  setValueDropdown={setValue}
                  optionDropdown={PERMISSION_OPTIONS}
                />
              </div>
              <div>
                <label className="block text-sm leading-[21px] mb-2 font-semibold text-gray-710">
                  {t('labels.Staffs.clinical_department')}
                </label>
                <div className="flex p-[1.5px] text-sm flex-wrap gap-2 items-center">
                  {departments.map(department => (
                    <Department
                      key={department.id}
                      name={department.displayName}
                      size="2xl"
                      iconUrl={department.iconUrl}
                      isActive={getValues('sections').includes(
                        department.section,
                      )}
                      onClick={() => handleChangeSection(department.section)}
                    />
                  ))}
                </div>
                {selectedSections.length <= 0 && (
                  <div className="feedback mt-1 text-xs text-red min-h-[20px]">
                    {errors.sections && errors.sections.message}
                  </div>
                )}
              </div>
            </div>
            <div className="basis-1/3">
              <div className="max-w-[100%]">
                <div className="mb-6">
                  <InputControl
                    value={valueTel}
                    control={control}
                    onBlur={handleOnBlurTel}
                    onChange={handleOnChangeTel}
                    name="tel"
                    type="text"
                    maxLength={11}
                    customBorder="rounded-lg"
                    fontSizeLabel="text-sm leading-[21px]"
                    classWrapper="mt-1"
                    label={`${t('labels.Staffs.phone_number')}`}
                  />
                </div>
                <div className="mb-6">
                  <InputControl
                    control={control}
                    name="email"
                    type="email"
                    label={`${t('labels.Staffs.email')}*`}
                    customBorder="rounded-lg"
                    fontSizeLabel="text-sm leading-[21px]"
                    classWrapper="mt-1"
                    disabled={staff.email !== ''}
                  />
                  {apiErrors?.email && (
                    <div className="feedback mt-1 text-xs text-red min-h-[20px]">
                      {apiErrors?.email}
                    </div>
                  )}
                </div>
                <div className="mb-6">
                  <label className="block text-sm leading-[21px] font-semibold text-gray-710">
                    {`${t('labels.Staffs.password')}*`}
                  </label>
                  <p className="text-[10px] pt-1 leading-[15px] font-light text-gray-710">
                    {t('labels.Staffs.alphanumeric_character')}
                    <br />
                    {t('labels.Staffs.half_width_alphanumeric_characters')}
                  </p>
                  <InputControl
                    {...inputPasswordProps(
                      'isFocusedPassword',
                      isFocused.isFocusedPassword,
                      'password',
                    )}
                  />
                  {apiErrors?.password && (
                    <div className="feedback mt-1 text-xs text-red min-h-[20px]">
                      {apiErrors?.password}
                    </div>
                  )}
                </div>
                <div className="mb-6">
                  <label className="block text-sm leading-[21px] font-semibold text-gray-710">
                    {`${t('labels.Staffs.confirm_password')}*`}
                  </label>
                  <InputControl
                    {...inputPasswordProps(
                      'isFocusedConfirmPassword',
                      isFocused.isFocusedConfirmPassword,
                      'passwordConfirmation',
                    )}
                  />
                </div>
                <div className="mb-6">
                  <InputControl
                    className="w-24 h-10"
                    control={control}
                    name="color"
                    type="color"
                    value={staffColor}
                    label={`${t('labels.Staffs.color')}*`}
                    customBorder="rounded-lg"
                    fontSizeLabel="text-sm leading-[21px]"
                    classWrapper="mt-1"
                    onChange={handleChangeColor}
                  />
                </div>
              </div>
            </div>
            <div className="basis-1/3 flex flex-col">
              <div className="flex justify-between">
                <div>
                  <p className="text-sm leading-[21px] mb-2 font-semibold text-gray-710">
                    {t('labels.Staffs.icon')}
                  </p>
                  <div className="flex flex-col items-center">
                    <div className="h-28 w-28 rounded-full overflow-hidden relative bg-[#E3EDFD]">
                      {profilePicture && (
                        <img
                          className="object-contain w-full h-full "
                          src={profilePicture}
                          alt=""
                        />
                      )}
                    </div>
                    <label
                      htmlFor="upload-profile-picture"
                      className="whitespace-nowrap cursor-pointer inline-block mt-2 px-4 py-[1.5px] bg-blue-610 text-white font-semibold text-sm leading-[21px] rounded-full"
                    >
                      {t('labels.Staffs.upload_picture')}
                    </label>
                    <input
                      className="hidden"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={e => handleChangeImage(e, 'profilePicture')}
                      id="upload-profile-picture"
                    />
                  </div>
                </div>
              </div>
              {isShowErrorUploadProFile && (
                <p className="feedback mt-1 text-xs text-red whitespace-pre-wrap">
                  {t('labels.UploadFile.max_size_image')}
                </p>
              )}
              <p className="text-sm leading-[21px] mt-6 mb-2 font-semibold">
                {t('labels.Staffs.HPKI_card')}
              </p>
              <div className="h-[200px] w-[317px] rounded-lg overflow-hidden relative bg-gray-80">
                {hpkiPicture && (
                  <img
                    className="object-contain w-full h-full "
                    src={hpkiPicture}
                    alt=""
                  />
                )}
              </div>
              <div className="flex flex-row mt-2">
                <label
                  htmlFor="upload-hpki-picture"
                  className="text-center px-4 py-[1.5px] whitespace-nowrap cursor-pointer inline-block bg-blue-610 text-white font-semibold text-sm leading-[21px] rounded-full"
                >
                  {t('labels.Staffs.upload_picture')}
                </label>
                <input
                  className="hidden"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={e => handleChangeImage(e, 'hpkiPicture')}
                  id="upload-hpki-picture"
                />
                <button
                  type="button"
                  onClick={() => setOpenPopupConfirmDelete(true)}
                  disabled={!hpkiPicture}
                  className={`ml-4 px-[22px] py-[1.5px] font-semibold text-sm leading-[21px] text-blue-610 border border-blue-610 rounded-full ${
                    !hpkiPicture ? 'cursor-not-allowed' : ''
                  }`}
                >
                  削除
                </button>
              </div>
              {isShowErrorUploadHpkiPicture && (
                <p className="feedback mt-1 text-xs text-red whitespace-pre-wrap">
                  {t('labels.UploadFile.max_size_image')}
                </p>
              )}
              <div className="text-[10px] leading-[15px] font-light mt-2 text-gray-710">
                <p>
                  {t(
                    'labels.Staffs.The_margins_of_the_image_are_also_included_in_the_display',
                  )}
                </p>
                <p>
                  {t(
                    'labels.Staffs.Please_process_and_upload_in_advance_so_that_there_are_no_blank_spaces',
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
      <PopupConfirm
        title="削除しますか？"
        open={openPopupConfirmDelete}
        theme="delete"
        setOpen={setOpenPopupConfirmDelete}
        handleFunctionProps={handleDeleteHpkiPicture}
      />
    </>
  )
}
