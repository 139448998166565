export default function IconTick() {
  return (
    <svg
      style={{
        height: '100%',
        width: '100%',
        padding: 10,
        boxSizing: 'border-box',
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10L6.83784 16L19 5"
        stroke="#3772D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
