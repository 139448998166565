import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import questionnaireResponseService from 'app/services/questionnaire-response'
import { Helmet } from 'react-helmet-async'
import { Pagination } from 'app/components/Pagination'
import { QuestionnaireResponse } from 'app/containers/QuestionerTemplate/types'
import { TableRowsLoading } from 'app/components/TableRowsLoading'
import { ModalMain } from 'app/components/ModalMain'
import { TableHeading } from './components/TableHeading'
import { TableRow } from './components/TableRow'
import { QuestionerResponseItem } from './QuestionerResponseItem'

export function QuestionerResponse() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetched, setIsFetched] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [staffs, setStaffs] = useState<any>()
  const [showItem, setShowItem] = useState<QuestionnaireResponse>()

  const fetchStaffsQuery = async (payload: any = {}) => {
    setIsLoading(true)
    const params = {
      nextKey: payload.pageParam || null,
      selectFields: 'patient',
      // page: payload.pageParam || 1,
    }
    const response: any = await questionnaireResponseService.findAll(params)
    setIsFetched(true)
    setIsLoading(!true)
    return response
  }

  const getNextPageParam = (lastPage: any) => {
    return lastPage.pagination.nextKey
  }

  const {
    data,
    // error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    // isFetchingNextPage,
  } = useInfiniteQuery(['staffs'], fetchStaffsQuery, {
    getNextPageParam,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (page > 1) fetchNextPage()
  }, [page])

  const handleLoadMore = () => {
    setPage(page + 1)
  }

  const handleClickLoadMore = () => {
    if (isFetched && !isFetching && data && hasNextPage) {
      handleLoadMore()
    }
  }

  const totalPage = data?.pages?.length
  useEffect(() => {
    setStaffs(data?.pages[currentPage - 1])
  }, [currentPage, []])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  const onShowItem = (item: QuestionnaireResponse) => {
    setShowItem(item)
  }

  return (
    <>
      <Helmet>
        <title>Questionnaire Response</title>
        <meta name="description" content="Questionnaire Response" />
      </Helmet>
      <div className="mt-5 border-b-[1px] border-gray-200">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Questionnaire Response
            </h1>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6">
            <div className="inline-block min-w-full py-2 align-middle md:px-6">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full">
                  <TableHeading />
                  <tbody className="bg-white divide-y divide-gray-200">
                    {staffs?.data.map(
                      (
                        questionnaireResponse: QuestionnaireResponse,
                        index: number,
                      ) => (
                        <TableRow
                          isLoading={isLoading}
                          key={questionnaireResponse.id}
                          index={index}
                          questionnaireResponse={questionnaireResponse}
                          onShow={onShowItem}
                        />
                      ),
                    )}
                    {isLoading && <TableRowsLoading colsNum={4} />}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          hasNextPage={hasNextPage}
          setCurrentPage={setCurrentPage}
          handleClickLoadMore={handleClickLoadMore}
        />
      </div>
      <ModalMain
        open={!!showItem}
        onClose={() => setShowItem(undefined)}
        size="large"
      >
        <div className="w-full">
          {showItem && (
            <QuestionerResponseItem questionnaireResponse={showItem} />
          )}
        </div>
      </ModalMain>
    </>
  )
}
