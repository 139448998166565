import React, { useEffect, useRef, useState } from 'react'
import { getSocketConnectUrl } from 'utils/websocket'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import {
  MedicalRecordIdsUpdatedType,
  addNotiNewReversation,
  selectMedicalRecordIdsUpdated,
  setMedicalRecordIdsUpdated,
} from 'features/common/commonSlice'
import { setEventJoined } from 'features/videoCalling/videoCallingSlice'
import { SOCKET_ACTIONS, actionPreventNotification } from 'app/config/websocket'
import { NOTI_WEBSOCKET } from 'app/config/app'
import { useAppSelector } from 'app/hooks'

interface Props {
  token: string
}

export function SocketListener(props: Props) {
  const dispatch = useDispatch()
  const { token } = props
  const webSocket = useRef<any>(null)
  const [wsTimer, setWsTimer] = useState<any>(null)
  const [wsTries, setWsTries] = useState<number>(0)
  const [receivedData, setReceivedData] = useState<any>(null)
  const [wsError, setWsError] = useState<any>(null)
  const [wsClose, setWsClose] = useState<any>(null)
  const socketHeartbeatDuration = 5 * 60 * 1000
  const medicalRecordIdsUpdated = useAppSelector(selectMedicalRecordIdsUpdated)

  const handleNotificationSound = () => {
    const audio: any = new Audio('/audio/notification.mp3')
    audio.play()
  }

  const log = (...rest: any) => {
    const enableLoging = !true
    if (enableLoging) {
      /* eslint-disable-next-line no-console */
      console.log(...rest)
    }
  }

  const notiUpdateReservations = (data: MedicalRecordIdsUpdatedType) => {
    const existing = medicalRecordIdsUpdated.find(
      item =>
        item.id === data.id &&
        item.action === data.action &&
        actionPreventNotification.includes(data.action),
    )

    if (existing) return // Skip: Do nothing

    const payload = [data]
    toast.success(NOTI_WEBSOCKET.UPDATE_RESERVATION)
    handleNotificationSound()
    dispatch(setMedicalRecordIdsUpdated(payload))
  }

  useEffect(() => {
    if (!receivedData) {
      return
    }
    /* eslint-disable-next-line no-console */
    log('receivedData', receivedData)
    const medicalRecordId = receivedData?.medicalRecordId

    switch (receivedData.action) {
      case SOCKET_ACTIONS.NEW_RESERVATION:
        /* eslint-disable-next-line no-console */
        log('[SOCKET] New Reservation')
        toast.success(NOTI_WEBSOCKET.NEW_RESERVATION)
        dispatch(addNotiNewReversation(new Date().getTime()))
        handleNotificationSound()
        break
      case SOCKET_ACTIONS.JOIN_EXAMINATION:
        dispatch(
          setEventJoined({
            medicalRecordId,
            id: new Date().getTime(),
          }),
        )
        break
      case SOCKET_ACTIONS.SELECT_PAYMENT:
        log('[SOCKET] Select Payment')
        notiUpdateReservations({
          id: medicalRecordId,
          action: receivedData.action,
        })
        break
      case SOCKET_ACTIONS.SUCCESS_PAYPAY:
        log('[SOCKET] Success PayPay')
        notiUpdateReservations({
          id: medicalRecordId,
          action: receivedData.action,
        })
        break
      case SOCKET_ACTIONS.ENTER_PHARMACY:
        log('[SOCKET] Enter Pharmacy')
        notiUpdateReservations({
          id: medicalRecordId,
          action: receivedData.action,
        })
        break
      default:
        break
    }
    /* eslint-disable-next-line */
  }, [receivedData])

  const handleKeepWSConnection = () => {
    /* eslint-disable-next-line no-console */
    log('------- handleKeepWSConnection -------')
    if (webSocket.current) {
      try {
        webSocket.current.send(
          JSON.stringify({
            action: 'ping',
          }),
        )
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    }
  }

  const handleReceivedWS = (data: any) => {
    /* eslint-disable-next-line no-console */
    log('------- handleReceivedWS@data -------', data)
    setReceivedData(data)
  }

  const handleWsError = (wsErrorData: any) => {
    const { event } = wsErrorData
    /* eslint-disable-next-line no-console */
    log('------- onerror -------', wsTries, event)
    if (wsTries <= 2) {
      /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
      startWebsocket()
      setWsTries(previous => previous + 1)
    }
  }
  const handleWsClose = (wsErrorData: any) => {
    const { event } = wsErrorData
    /* eslint-disable-next-line no-console */
    log('------- onclose -------', wsTries, event)
    if (event.code !== 1005 && wsTries <= 2) {
      /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
      setTimeout(startWebsocket, 2000)
    }
  }

  useEffect(() => {
    if (wsError) {
      handleWsError(wsError)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [wsError])

  useEffect(() => {
    if (wsClose) {
      handleWsClose(wsClose)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [wsClose])

  const onWsError = (eventWs: any) => {
    setWsError({
      id: new Date().getTime(),
      event: eventWs,
    })
  }
  const onWsClose = (eventWs: any) => {
    setWsClose({
      id: new Date().getTime(),
      event: eventWs,
    })
  }

  const startWebsocket = () => {
    /* eslint-disable-next-line no-console */
    log('------ startWebsocket ------')
    webSocket.current = new WebSocket(getSocketConnectUrl({ token }))
    webSocket.current.onmessage = (entry: any) => {
      /* eslint-disable-next-line no-console */
      log('------ onmessage ------', entry?.data)
      try {
        if (entry.data && entry.data.length >= 5) {
          handleReceivedWS(JSON.parse(entry.data))
        }
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error('onmessage', entry, error)
      }
    }

    webSocket.current.onopen = (eventWs: any) => {
      /* eslint-disable-next-line no-console */
      log('------ onopen ------', eventWs)
      if (wsTimer) {
        clearInterval(wsTimer)
      }
      setWsTimer(setInterval(handleKeepWSConnection, socketHeartbeatDuration))
    }

    webSocket.current.onerror = (eventWs: any) => {
      onWsError(eventWs)
    }

    webSocket.current.onclose = (eventWs: any) => {
      onWsClose(eventWs)
    }
  }

  useEffect(() => {
    startWebsocket()
    return () => {
      clearInterval(wsTimer)
      if (webSocket.current) {
        webSocket.current.close()
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return <div className="hidden">SocketListener</div>
}

export const MemorizedSocketListener = React.memo(SocketListener)
