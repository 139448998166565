import { Department } from 'types/Department'
import { apiClient } from './api'

export const getDepartment = async (departmentId: string) => {
  const data: any = await apiClient.get(
    `/api/v1/departments/${departmentId}`,
    {},
  )
  return data
}

export const getDepartments = async () => {
  const data: Department[] = await apiClient.get(`/api/v1/departments`)
  return data
}
export const updateQuestionnaire = async (questionnaire: any) => {
  const data: any = await apiClient.patch(
    `/api/v1/questionnaire-templates`,
    questionnaire,
  )
  return data
}

export const getSections = async () => {
  const data: any = await apiClient.get(`/api/v1/departments/sections`, {})
  return data
}
