import { NO_ANSWER } from 'app/config/app'
import { ItemWithQuestionSelectDate } from '../../types'

export function InputItemSelectDate({
  item,
  index,
}: {
  item: ItemWithQuestionSelectDate
  index: number
}) {
  const resolveValue = () => {
    const year = item.questionItem.question.dateSelectQuestion.response?.year
    const month = item.questionItem.question.dateSelectQuestion.response?.month
    const day = item.questionItem.question.dateSelectQuestion.response?.day
    if (year) {
      return `${year}年${month}月${day}日`
    }
    if (month && day) {
      return `${month}月${day}日`
    }
    return NO_ANSWER
  }

  return <span className={`index-${index}`}>{resolveValue()}</span>
}
