import classNames from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { getVisiblePages } from 'utils/helper'
import { useEffect } from 'react'
import { CURRENT_PAGE } from 'app/config/app'

interface PaginationProps {
  currentPage: any
  totalPage: any
  hasNextPage: Boolean | undefined
  setCurrentPage: (value: any) => void
  handleClickLoadMore: () => void
}

export function Pagination(props: PaginationProps) {
  const {
    currentPage,
    setCurrentPage,
    totalPage,
    hasNextPage,
    handleClickLoadMore,
  } = props
  const isNext = currentPage < totalPage

  const handleClickPage = (page: number) => {
    setCurrentPage(page)
    localStorage.setItem(CURRENT_PAGE, JSON.stringify(page))
  }

  if (totalPage === 1) {
    localStorage.removeItem(CURRENT_PAGE)
  }

  const localStorageCurrentPage = Number(localStorage.getItem(CURRENT_PAGE))
  useEffect(() => {
    if (localStorageCurrentPage) {
      setCurrentPage(localStorageCurrentPage)
    } else {
      setCurrentPage(totalPage)
    }
  }, [totalPage])

  const handleOnNextPage = () => {
    if (isNext) {
      setCurrentPage(currentPage + 1)
      localStorage.removeItem(CURRENT_PAGE)
    } else {
      handleClickLoadMore()
      localStorage.removeItem(CURRENT_PAGE)
    }
  }

  return (
    <div className="mt-6 flex justify-center">
      <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px">
          <li>
            <button
              type="button"
              className={classNames(
                'rounded-l-lg bg-white py-1.5 px-3 leading-tight hover:text-gray-800',
                {
                  'cursor-not-allowed': currentPage === 1,
                  'text-gray-600 hover:text-gray-700': currentPage !== 1,
                  'text-gray-300 hover:text-gray-300': currentPage === 1,
                },
              )}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
          </li>
          {getVisiblePages(totalPage, currentPage).map((page, index, array) => {
            const isActive = currentPage === page
            return (
              <li key={index}>
                <button
                  type="button"
                  className={classNames(
                    ' bg-white py-[6px] px-3 leading-tight text-gray-500 hover:text-gray-800',
                    {
                      'text-gray-800': isActive,
                    },
                  )}
                  onClick={() => handleClickPage(page)}
                >
                  {array[index - 1] + 1 < page
                    ? `...\u00A0\u00A0\u00A0\u00A0${page}`
                    : page}
                </button>
              </li>
            )
          })}
          <li>
            <span
              className={classNames(
                'flex bg-white py-[6px] px-3 leading-tight text-gray-500 hover:text-gray-800',
                {
                  hidden: !hasNextPage,
                },
              )}
            >
              ...
            </span>
          </li>
          <li>
            <button
              type="button"
              className={classNames(
                'rounded-r-lg bg-white py-1.5 px-3 leading-tight hover:text-gray-800',
                {
                  'cursor-not-allowed': !hasNextPage && !isNext,
                  'text-gray-600 hover:text-gray-700': hasNextPage && isNext,
                  'text-gray-300 hover:text-gray-300': !hasNextPage && !isNext,
                },
              )}
              onClick={handleOnNextPage}
              disabled={!hasNextPage && !isNext}
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}
