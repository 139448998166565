import styled from 'styled-components'

export const WrapperMainContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .ch-nameplate {
    display: none;
  }
`

export const WrapperMainContentCol = styled.div`
  width: 25%;
  height: 25%;
  border-top: 0px;
  background: #000;
  position: relative;
`

export const WrapperMainContentColContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &.item-hightlight {
    .x-remote-video {
      border-color: #f18d01 !important;
      video {
        border-radius: 0.375rem;
      }
    }
    .remote-video-off {
      border-color: #f18d01;
    }
    .attendee-name {
      left: 8px;
      bottom: 0px;
      background: #f18d01;
      border-radius: 0px;
    }
  }
`
