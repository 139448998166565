export const meetingConfigValue: any = {}

export const MEETING_APP = {
  DUMMY_DOCTOR: 'DUMMY_DOCTOR',
  DOCTOR_PREFIX: 'DOCTOR_',
  DUMMY_DOCTOR_PREFIX: 'DUMMY-DOCTOR_',
  SUPPORT_DUMMY: !true,
}

export const VIDEO_CALL_EVENTS = {
  offline: 'offline',
  doctorLeave: 'doctorLeave',
  doctorJoinedAndLeave: 'doctorJoinedAndLeave',
  allAttendeesJoined: 'allAttendeesJoined',
  meetingStartedSuccess: 'meetingStartedSuccess',
  permissionDenied: 'permissionDenied',
}

// eslint-disable-next-line no-shadow
export enum DeviceLabels {
  None,
  Audio,
  Video,
  AudioAndVideo,
}

export const VIDEO_INPUT_QUALITY = {
  '360p': '360p (nHD) @ 15 fps (600 Kbps max)',
  '540p': '540p (qHD) @ 15 fps (1.4 Mbps max)',
  '720p': '720p (HD) @ 15 fps (1.4 Mbps max)',
}

export const ADMIN = 'admin'
export const DIRECTOR = 'director'
export const AUDIENCE = 'audience'
export const ARTIST = 'artist'
export const ARTIST_PREFIX = 'artist_'

export const MEETING_LAYOUT = {
  videoContainerWidth: 250,
  videoContainerWidthMobile: 175,
  videoContainerHeight: 140,
  videoContainerHeightMobile: 75,
  videoHeaderHeight: 40,
  videoHeaderHeightMobile: 35,
  radius: 0,
}

export const MEETING_STATES = {
  PENDING: 'PENDING',
  JOINING: 'JOINING',
  JOINED: 'JOINED',
}
