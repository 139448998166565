import styled from 'styled-components'

export const ArrowDownDepartment = styled.div`
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  top: -9px;
  z-index: 2;
  box-sizing: border-box;
  border: 8px solid transparent;
  border-color: transparent transparent #fefefe #fefefe;
  transform: rotate(-45deg);
  box-shadow: -3px 3px 3px 0 rgba(87, 104, 116, 0.3);
`

export const ArrowUpDepartment = styled.div`
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  top: -7px;
  z-index: 2;
  box-sizing: border-box;
  border: 8px solid transparent;
  border-color: transparent transparent #fefefe #fefefe;
  transform: rotate(135deg);
  box-shadow: -3px 3px 3px 0 rgba(87, 104, 116, 0.3);
`

export const ArrowDownDepartmentName = styled.div`
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  top: -9px;
  z-index: 2;
  box-sizing: border-box;
  border: 8px solid transparent;
  border-color: transparent transparent #3772d0 #3772d0;
  transform: rotate(-45deg);
  box-shadow: -3px 3px 3px 0 rgba(87, 104, 116, 0.3);
`
