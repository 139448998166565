const taskTodayFields = ['doctor', 'billing']

const todaysMedicalExaminationsFields = ['doctor']

const shippingAddressFields = ['delivery', 'billing']

const staffsFields = ['name']

const staffV2Fields = ['profilePictureUrl', 'departments']

const getClinicInformation = ['doctors']

const getTodaysMedicalExamination = ['']

export const selectFieldResolver = {
  /** OMM-011 */
  getTaskToday: () => {
    return taskTodayFields.join(',')
  },
  getTodaysMedicalExaminations: () => {
    return todaysMedicalExaminationsFields.join(',')
  },
  /** OMM-012 */
  getShippingAddress: () => {
    return shippingAddressFields.join(',')
  },
  /** OMM-060 */
  getStaffs: () => {
    return staffsFields.join(',')
  },
  /** OMM-020 */
  getStaffsV2: () => {
    return staffV2Fields.join(',')
  },
  /** OMM-061 */
  getClinicInformation: () => {
    return getClinicInformation.join(',')
  },
  /** OMM-010 */
  getTodaysMedicalExamination: () => {
    return getTodaysMedicalExamination.join(',')
  },
}
