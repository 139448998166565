import { tabs } from 'app/config/app'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  selectIsModified,
  setPathChoice,
  setShowPopupGlobal,
} from 'features/tabReducer/tabReducerSlice'
import { useLocation, useNavigate } from 'react-router-dom'

export default function useChangeTab() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isModified = useAppSelector(selectIsModified)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tabValue = searchParams.get('tab') as string

  const handleMultipleChangeTab = (pathname: string) => {
    if (isModified && tabValue === tabs.HOSPITAL_SETTING) {
      dispatch(setShowPopupGlobal(true))
      dispatch(setPathChoice(pathname))
    } else {
      dispatch(setShowPopupGlobal(false))
      navigate(pathname)
    }
  }

  return handleMultipleChangeTab
}
