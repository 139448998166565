import styled from 'styled-components'

export const StyleVideoPreviewContainer = styled.section`
  position: relative;
  background: #000;
  background: transparent;
  // height: calc(700px * 0.5625);
  height: 300px;
  background: #000;
  @media screen and (max-width: 769px) {
    height: calc((100vw - 40px) * 0.5625);
  }
`

export const StyleVideo = styled.video`
  width: 100%;
`

export const MicrophoneActivityPreviewBarStyle = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`
