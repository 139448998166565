import { useEffect, useState } from 'react'
import {
  useRemoteVideoTileState,
  useRosterState,
  // useLocalVideo,
  // useLocalAudioOutput,
  // useToggleLocalMute,
  // useAudioVideo,
} from 'amazon-chime-sdk-component-library-react'
import { arrangeAttendees } from 'utils/meeting'
import { MeetingRemoteVideo } from 'app/components/MeetingRemoteVideo'
import { MEETING_APP } from 'app/config/meeting'
import { WrapperMainContent, WrapperMainContentColContainer } from './Style'

interface VideoItemProps {
  attendee: any
}

export function RemoteMeetings() {
  const { roster } = useRosterState()
  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState()
  const [attendees, setAttendees] = useState<any[]>([])

  const parseAudienceFromRoster = (rosterData: any) =>
    Object.values(rosterData).filter(
      (attendee: any) =>
        attendee.externalUserId.indexOf(MEETING_APP.DOCTOR_PREFIX) !== 0,
    )

  // ndx-todo: k show name.
  const getAttendeeItems = () => {
    const filterRoster: any = parseAudienceFromRoster(roster)
    const audienceAttendees = tiles.map((tileId: any) => {
      const attendee = filterRoster[tileIdToAttendeeId[tileId]] || {}
      const attendeeId = tileIdToAttendeeId[tileId]
      return {
        ...attendee,
        AttendeeId: attendee.chimeAttendeeId,
        attendeeId,
        tileId,
      }
    })

    return arrangeAttendees(audienceAttendees)
  }

  useEffect(() => {
    setAttendees(getAttendeeItems())
  }, [tiles, roster, tileIdToAttendeeId])

  const resolveAttendeeId = (attendee: any) =>
    attendee.AttendeeId || attendee.attendeeId

  function VideoItem({ attendee }: VideoItemProps) {
    return (
      <WrapperMainContentColContainer>
        <MeetingRemoteVideo attendee={attendee} isHightlight />
      </WrapperMainContentColContainer>
    )
  }

  return (
    <WrapperMainContent>
      {attendees.map((attendee: any) => (
        <VideoItem attendee={attendee} key={resolveAttendeeId(attendee)} />
      ))}
    </WrapperMainContent>
  )
}
