import React, { useContext, useState, ReactNode, useMemo } from 'react'
import { Meeting, JoinMeetingInfo } from 'types/Meeting'
import { Attendee } from 'types/Attendee'

type Props = {
  children: ReactNode
}

interface AppStateValue {
  meeting: Meeting | null
  attendee: Attendee | null
  isWebAudioEnabled: boolean
  isEchoReductionEnabled: boolean
  joinInfo: JoinMeetingInfo | undefined
  setAppMeetingInfo: (meeting: Meeting, attendee: Attendee) => void
  setJoinInfo: (joinInfo: JoinMeetingInfo | undefined) => void
}

const AppStateContext = React.createContext<AppStateValue | null>(null)

export function useAppState(): AppStateValue {
  const state = useContext(AppStateContext)

  if (!state) {
    throw new Error('useAppState must be used within AppStateProvider')
  }

  return state
}

export function AppStateProvider({ children }: Props) {
  const [meeting, setMeeting] = useState<Meeting | null>(null)
  const [attendee, setAttendee] = useState<Attendee | null>(null)
  const [isWebAudioEnabled, setIsWebAudioEnabled] = useState(true)
  const [isEchoReductionEnabled, setIsEchoReductionEnabled] = useState(false)
  const [joinInfo, setJoinInfo] = useState<JoinMeetingInfo | undefined>(
    undefined,
  )

  const toggleWebAudio = (): void => {
    setIsWebAudioEnabled(current => !current)
  }

  const setAppMeetingInfo = (meetingData: Meeting, attendeeData: Attendee) => {
    setMeeting(meetingData)
    setAttendee(attendeeData)
  }

  const toggleEchoReduction = (): void => {
    setIsEchoReductionEnabled(current => !current)
  }

  const providerValue = useMemo(
    () => ({
      meeting,
      attendee,
      isWebAudioEnabled,
      joinInfo,
      isEchoReductionEnabled,
      setAppMeetingInfo,
      toggleWebAudio,
      setJoinInfo,
      toggleEchoReduction,
    }),
    [],
  )

  return (
    <AppStateContext.Provider value={providerValue}>
      {children}
    </AppStateContext.Provider>
  )
}
