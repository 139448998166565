import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  useLocalVideo,
  useAudioVideo,
  useApplyVideoObjectFit,
} from 'amazon-chime-sdk-component-library-react'
import { BaseSdkProps } from 'app/components/Meeting/sdk/Base'
import { VideoTile } from './ui/VideoTile'

interface Props extends BaseSdkProps {
  // eslint-disable-next-line
  id?: string
  // eslint-disable-next-line
  nameplate?: string
}

const StyledLocalVideo = styled<any>(VideoTile)`
  ${props => (!props.active ? 'display: none' : '')};
`
// eslint-disable-next-line
export const MeetingLocalVideo: React.FC<Props> = ({ nameplate, ...rest }) => {
  const { tileId, isVideoEnabled } = useLocalVideo()
  const audioVideo = useAudioVideo()
  const videoEl = useRef<HTMLVideoElement>(null)
  useApplyVideoObjectFit(videoEl)

  useEffect(() => {
    if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
      return
    }

    audioVideo.bindVideoElement(tileId, videoEl.current)

    return () => {
      const tile = audioVideo.getVideoTile(tileId)
      if (tile) {
        audioVideo.unbindVideoElement(tileId)
      }
    }
  }, [audioVideo, tileId, isVideoEnabled])

  return (
    <StyledLocalVideo
      active={isVideoEnabled || true}
      nameplate={nameplate}
      className="x-remote-video"
      ref={videoEl}
      {...rest}
    />
  )
}

export default MeetingLocalVideo
