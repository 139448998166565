export default function index() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.95 16.9125C13.65 16.6125 13.506 16.2499 13.518 15.8249C13.53 15.4 13.6865 15.0375 13.9875 14.7375L18.225 10.5H1.50001C1.07501 10.5 0.718505 10.356 0.430505 10.068C0.142505 9.77995 -0.00099481 9.42395 5.1903e-06 8.99995C5.1903e-06 8.57495 0.144005 8.21845 0.432005 7.93045C0.720005 7.64245 1.07601 7.49895 1.50001 7.49995H18.225L13.95 3.22495C13.65 2.92495 13.5 2.56845 13.5 2.15545C13.5 1.74245 13.65 1.38645 13.95 1.08745C14.25 0.787451 14.6065 0.637451 15.0195 0.637451C15.4325 0.637451 15.7885 0.787451 16.0875 1.08745L22.95 7.94995C23.1 8.09995 23.2065 8.26245 23.2695 8.43745C23.3325 8.61245 23.3635 8.79995 23.3625 8.99995C23.3625 9.19995 23.331 9.38745 23.268 9.56245C23.205 9.73745 23.099 9.89995 22.95 10.05L16.05 16.9499C15.775 17.2249 15.4315 17.3625 15.0195 17.3625C14.6075 17.3625 14.251 17.2125 13.95 16.9125Z"
        fill="#516CC3"
      />
    </svg>
  )
}
