export const ROUTES = {
  HOME: '/',
  STAFF: {
    INDEX: '/staffs',
    CREATE: '/staffs/create',
    UPDATE: '/staffs/update/:staffId',
  },
  MASTER_SETTING: {
    INDEX: '/settings',
    HOSPITAL: '/setting-hospital',
  },
  AUTH: {
    LOGIN: '/login',
  },
  PATIENTS: {
    INDEX: '/patients',
    DETAIL: '/patient/:patientId',
  },
  ACCOUNTING: {
    INDEX: '/accounting',
  },
  ANALYSIS: {
    INDEX: '/analysis',
  },
  QUESTIONNAIRE_RESPONSE: {
    INDEX: '/questionnaire-response',
  },
  ABOUT: '/about',
  DUMMY_ATTENDEE: '/da/:token',
}
