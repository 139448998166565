import { useAppSelector } from 'app/hooks'
import { selectCurrentUser } from 'features/auth/authSlice'
import { User } from 'types/User'

export function AccountIcon() {
  const user: User = useAppSelector(selectCurrentUser)
  const imgUrl = user?.profilePictureUrl || '/default_avatar.png'

  return user ? (
    <div className="flex items-center text-white ml-[10px]">
      <div>
        <div className="font-medium break-all">
          <span className="mr-1 laptop:text-[16px] font-light">
            {user.name}
          </span>
        </div>
      </div>
      <div className="ml-4 h-10 w-10 flex-shrink-0">
        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
          <img
            src={imgUrl}
            alt=""
            className="text-sm font-medium leading-none text-white rounded-full h-10 w-10"
          />
        </span>
      </div>
    </div>
  ) : null
}
