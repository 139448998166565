import * as yup from 'yup'

const NAME_REGEX = /^[a-zA-Zａ-ｚＡ-Ｚぁ-んァ-ンｧ-ﾝﾞﾟ一-龠々ヵヶ卜ー-\s]+$/
const REGEX_NAME_KANA = /^([ァ-ンｧ-ﾝﾞﾟー-\s])+$/
const PERMISSION = ['doctor', 'staff', 'tester']
const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.\-@_])[A-Za-z\d.\-@_]{13}$/ // eslint-disable-line
const EMAIL_REGEX =
  /^[a-zA-Z0-9]+([._\-+]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*(\.[a-zA-Z]{2,})+$/
const TEL_REGEX = /^0\d{9,10}$/

export const createSchema = yup.object().shape({
  name: yup
    .string()
    .required('必須入力')
    .matches(
      NAME_REGEX,
      'ひらがな、漢字、英字、またはカタカナで入力してください',
    ),
  nameKana: yup
    .string()
    .required('必須入力')
    .max(60, 'このフィールドは正しい形式ではありません')
    .matches(REGEX_NAME_KANA, 'カタカナをご入力してください'),
  email: yup
    .string()
    .email('正しいメールアドレスを入力してください')
    .matches(EMAIL_REGEX, '正しいメールアドレスを入力してください')
    .max(250, '250 文字を超えてはいけません'),
  tel: yup.string().matches(TEL_REGEX, {
    excludeEmptyString: true,
    message: '電話番号は正しくありません',
  }),
  sections: yup
    .array()
    .of(yup.string().required('選択してください'))
    .min(1, '選択してください'),
  password: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      `英字・数字・記号をそれぞれ一つ以上含む、13文字で入力してください\n半角英数字・半角記号（ドット、ハイフン、アンダーバー、アットマーク）を使用してください`,
    ),
  passwordConfirmation: yup
    .string()
    .required('パスワードが一致しません')
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません'),
  permission: yup.string().oneOf(PERMISSION, '権限は選択する必要がある'),
})

export const updateSchema = yup.object().shape({
  name: yup
    .string()
    .required('必須入力')
    .matches(
      NAME_REGEX,
      'ひらがな、漢字、英字、またはカタカナで入力してください',
    ),
  nameKana: yup
    .string()
    .required('必須入力')
    .max(60, 'このフィールドは正しい形式ではありません')
    .matches(REGEX_NAME_KANA, 'カタカナをご入力ください'),
  email: yup
    .string()
    .email('正しいメールアドレスを入力してください')
    .matches(EMAIL_REGEX, '正しいメールアドレスを入力してください')
    .max(250, '250 文字を超えてはいけません'),
  tel: yup.string().matches(TEL_REGEX, {
    excludeEmptyString: true,
    message: '電話番号は正しくありません',
  }),
  sections: yup
    .array()
    .of(yup.string().required('選択してください'))
    .min(1, '選択してください'),
  password: yup.string().matches(PASSWORD_REGEX, {
    message: `英字・数字・記号をそれぞれ一つ以上含む、13文字で入力してください\n半角英数字・半角記号（ドット、ハイフン、アンダーバー、アットマーク）を使用してください`,
    excludeEmptyString: true,
  }),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません'),
  permission: yup.string().optional(),
})
