import styled from 'styled-components'

export const LocalVideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotateY(360deg) !important;
  }
`

export const VideoOffCameraMain = styled.div`
  display: flex;
  justify-content: center;
  background: #333333;
  height: 100%;
  color: #fff;
  border-radius: 5px;
  border: 8px solid #333333;
  align-items: center;
  svg {
    width: 60%;
    display: inline-block;
    height: 50%;
  }
`
export const RemoteVideoPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #333333;
  border-radius: 5px;
  border: 8px solid #000;
`
