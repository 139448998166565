import { format, isAfter, startOfToday } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { ja } from 'date-fns/locale'
import { TIME_ZONE_JP } from 'app/config'

export const formatLocalDateTime = (date: Date | string) =>
  typeof date === 'string'
    ? format(new Date(date), 'yyyy/M/d HH:mm:ss')
    : format(date, 'yyyy/M/d HH:mm:ss')

export const fulldateWeekFormat = (
  date: any,
  targetFormat: string = 'yyyy/M/d(EEE)',
) => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat, {
    locale: ja,
  })
}
export const fullDateTimeWeekFormat = (
  date: any,
  targetFormat: string = 'yyyy/M/d(EEE) HH:mm',
) => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat, {
    locale: ja,
  })
}
const dateTimeFormat = (
  date: Date,
  targetFormat: string = 'yyyy/M/d HH:mm',
) => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat)
}
const dateFormat = (date: Date, targetFormat: string = 'yyyy/M/d') => {
  return format(date, targetFormat)
}
const dateFormatUTC = (date: Date, targetFormat: string = 'yyyy/M/d(EEE)') => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat, {
    locale: ja,
  })
}
const dateFormatToParamAPi = (
  date: Date,
  targetFormat: string = 'yyyy-MM-dd',
) => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat)
}
const shortTimeFormatJp = (date: Date, targetFormat: string = 'HH:mm') => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat)
}
const shortTimeFormat = (date: Date, targetFormat: string = 'HH:mm') => {
  return format(date, targetFormat)
}

const isAfterNow = (date: Date) => {
  const now = startOfToday()
  return isAfter(date, now)
}
const estimatedTime = (timeStart: Date, timeEnd: Date) => {
  const difference =
    utcToZonedTime(new Date(timeEnd), TIME_ZONE_JP).getTime() -
    utcToZonedTime(new Date(timeStart), TIME_ZONE_JP).getTime() // This will give difference in milliseconds
  const resultInMinutes = Math.round(difference / 60000)
  return resultInMinutes
}
const dateFormatToJapan = (
  date: Date,
  targetFormat: string = 'yyyy年M月d日',
) => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat)
}
const dateTimeFormatFullCalendar = (date: string, time: string) => {
  const dateStr = utcToZonedTime(new Date(date), TIME_ZONE_JP)
  const dateFormatStr = dateStr.toISOString().replace(/T.*$/, '')
  return `${dateFormatStr}T${time}`
}

const dataTimePicker = () => {
  const results = []
  for (let i = 9; i <= 18; i += 1) {
    const hour = `0${i}`.slice(-2)
    if (i < 18) {
      for (let j = 0; j < 4; j += 1) {
        const minute = `${j === 0 ? `00` : 15 * j}`
        results.push({
          value: `${hour}:${minute}`,
          label: `${hour}:${minute}`,
        })
      }
    } else {
      results.push({ value: `${hour}:00`, label: `${hour}:00` })
    }
  }
  return results
}

const getLastDayOfNextMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 2, 0)
}

const currentInUtc = (
  date: Date,
  targetFormat: string = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
) => {
  return format(utcToZonedTime(new Date(date), TIME_ZONE_JP), targetFormat)
}
const formatTimeHourMinute = (time: string) => {
  const timeArr = time.split(':')
  return `${timeArr[0]}:${timeArr[1]}`
}

const getTimeFromStringTime = (time: any) => {
  const [minute, second] = time.split(':')
  const currentDate = new Date()
  currentDate.setHours(minute)
  currentDate.setMinutes(second)

  return currentDate.getTime()
}

const calculationDiffInMinutes = (startAt: string, endAt: string) => {
  const diffInMs = getTimeFromStringTime(endAt) - getTimeFromStringTime(startAt)
  const diffInMinutes = Math.round(diffInMs / (1000 * 60))

  return diffInMinutes
}

function checkTimeSlot(start: string, end: string) {
  const startHour = parseInt(start.split(':')[0], 10)
  const endHour = parseInt(end.split(':')[0], 10)
  if (
    startHour === endHour ||
    end.split(':')[1] === '00' ||
    end.split(':')[1] === '30'
  )
    return true
  return false
}

const formatDateToJapanese = (dateStr: string) => {
  const [year, month, day] = dateStr.split('-')
  const formattedDate = `${year}年${parseInt(month, 10)}月${parseInt(
    day,
    10,
  )}日`
  return formattedDate
}

export {
  dateTimeFormat,
  dateFormat,
  isAfterNow,
  estimatedTime,
  dateFormatToParamAPi,
  dateFormatToJapan,
  shortTimeFormatJp,
  dateTimeFormatFullCalendar,
  dataTimePicker,
  shortTimeFormat,
  dateFormatUTC,
  getLastDayOfNextMonth,
  currentInUtc,
  formatTimeHourMinute,
  calculationDiffInMinutes,
  checkTimeSlot,
  formatDateToJapanese,
}
