import { NO_ANSWER } from 'app/config/app'
import { ItemWithQuestionChoice } from '../../types'

export function InputItemDropdown({
  item,
  index,
}: {
  item: ItemWithQuestionChoice
  index: number
}) {
  const resolveValue = () => {
    const response =
      item.questionItem.question.choiceQuestion?.value || NO_ANSWER
    return response
  }

  return <span className={`index-${index}`}>{resolveValue()}</span>
}
