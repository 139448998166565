import { ClockIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { TextareaControl } from 'app/components/Base'
import { MEDICAL_EXAMINATION_STATUS } from 'app/config/app'
import { DEFAULT_DELAY_MESSAGE } from 'app/config/message'
import { useAppSelector } from 'app/hooks'
import { sendMessageToDoctor } from 'app/services/medical-examination'
import { getTodaysMedicalExaminations } from 'app/services/task'
import { selectCurrentUser } from 'features/auth/authSlice'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Task } from 'types/Task'
import { User } from 'types/User'
import {
  dateFormatToParamAPi,
  estimatedTime,
  shortTimeFormatJp,
} from 'utils/datetime'
import { filterMedicalHistory } from 'utils/helper'
import { v4 } from 'uuid'
import * as yup from 'yup'

export default function NotifyNewMedicalExamination() {
  const { t } = useTranslation()
  const user: User = useAppSelector(selectCurrentUser)
  const [infoPatientEarly, setInfoPatientEarly] = useState<any>()
  const [infoPatientLate, setInfoPatientLate] = useState<Task[]>([])
  const [isStackingNotifyLate, setIsStackingNotifyLate] =
    useState<boolean>(true)
  const [isStackingNotifyEarly, setIsStackingNotifyEarly] =
    useState<boolean>(true)
  const [isDisplayNotifyLate, setIsDisplayNotifyLate] = useState<boolean>(true)
  const [isDisplayNotifyEarly, setIsDisplayNotifyEarly] =
    useState<boolean>(true)
  const [medicalRecordId, setMedicalRecordId] = useState<string>('')
  const [openPopupMessage, setOpenPopupMessage] = useState<string>('')
  const [medicalExaminationsToday, setMedicalExaminationsToday] = useState<
    Task[]
  >([])

  const {
    mutateAsync: mutateAsyncDelayExamination,
    isLoading: isLoadingDelayExamination,
  } = useMutation({
    mutationFn: (data: object) => sendMessageToDoctor(medicalRecordId, data),
  })

  const fetchMedicalRecordItems = async (presentTime: Date) => {
    const data: Task[] = await getTodaysMedicalExaminations(
      dateFormatToParamAPi(presentTime),
    )
    setMedicalExaminationsToday(data)
  }

  const displayDelayTime = (infoPatient: any) => {
    return estimatedTime(
      new Date(),
      new Date(infoPatient?.reservation?.beginAt),
    ) < 0
      ? estimatedTime(new Date(), new Date(infoPatient?.reservation?.beginAt))
          .toString()
          .substring(1)
      : 0
  }

  useEffect(() => {
    fetchMedicalRecordItems(new Date())
    const intervalTime = setInterval(() => {
      fetchMedicalRecordItems(new Date())
    }, 60000)
    return () => {
      clearInterval(intervalTime)
    }
  }, [])

  useEffect(() => {
    const patientList =
      medicalExaminationsToday &&
      medicalExaminationsToday.filter(
        patient => patient?.doctor?.id === user.id,
      )

    const patientIsEarly =
      patientList &&
      patientList.filter(
        (patient: any) =>
          estimatedTime(new Date(), new Date(patient?.reservation?.beginAt)) >
            0 &&
          estimatedTime(new Date(), new Date(patient?.reservation?.beginAt)) <
            15 &&
          (patient?.status ===
            MEDICAL_EXAMINATION_STATUS.WAITING_CONSULTATION ||
            patient?.status ===
              MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION_STARTED ||
            patient?.status ===
              MEDICAL_EXAMINATION_STATUS.ENTERING_MEDICAL_DOCUMENTS),
      )

    if (patientIsEarly.length > 0) {
      const patientIsEarlyFilter = filterMedicalHistory(patientIsEarly)
      setInfoPatientEarly(patientIsEarlyFilter)
    } else {
      setInfoPatientEarly(null)
    }

    const patientIsLate =
      patientList &&
      patientList.filter(
        (patient: any) =>
          estimatedTime(new Date(), new Date(patient?.reservation?.beginAt)) <
            1 &&
          (patient?.status ===
            MEDICAL_EXAMINATION_STATUS.WAITING_CONSULTATION ||
            patient?.status ===
              MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION_STARTED ||
            patient?.status ===
              MEDICAL_EXAMINATION_STATUS.ENTERING_MEDICAL_DOCUMENTS),
      )

    const patientIsLateList = filterMedicalHistory(patientIsLate)

    setInfoPatientLate(patientIsLateList)
  }, [medicalExaminationsToday])

  const handleToggleDisplayNotify = (status: string) => {
    if (status === 'late') {
      setIsDisplayNotifyLate(!isDisplayNotifyLate)
      setIsStackingNotifyLate(true)
      setIsStackingNotifyEarly(true)
    }
    if (status === 'early') {
      setIsDisplayNotifyEarly(!isDisplayNotifyEarly)
      setIsStackingNotifyEarly(true)
    }
    setOpenPopupMessage('')
  }

  const handleToggleStyleNotify = (status: string) => {
    if (status === 'late' && infoPatientLate.length > 1) {
      setIsStackingNotifyLate(!isStackingNotifyLate)
      setIsStackingNotifyEarly(true)
    }
    if (status === 'early' && infoPatientEarly.length > 1) {
      setIsStackingNotifyEarly(!isStackingNotifyEarly)
      if (isStackingNotifyEarly) {
        setIsDisplayNotifyLate(false)
      }
    }
    setOpenPopupMessage('')
  }

  const validations = yup.object().shape({
    message: yup.string().required('このフィールドは必須です'),
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validations),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      message: DEFAULT_DELAY_MESSAGE,
    },
  })

  const handleSendMessage = async (data: any) => {
    if (medicalRecordId) {
      try {
        await mutateAsyncDelayExamination(data)
        await setOpenPopupMessage('')
        reset({
          message: DEFAULT_DELAY_MESSAGE,
        })
        setOpenPopupMessage('')
        toast.success('送信しました')
      } catch (error) {
        toast.error('Something went wrong')
      }
    }
  }

  const handleShowPopupMessage = (id: string) => {
    reset({
      message: DEFAULT_DELAY_MESSAGE,
    })
    setOpenPopupMessage(id)
    setMedicalRecordId(id)
  }

  return (
    <div>
      {infoPatientLate && infoPatientLate.length > 0 && (
        <div
          className={`fixed z-30 bottom-2 ${
            infoPatientEarly ? 'bottom-24' : 'bottom-2'
          }`}
        >
          <div
            className="w-[60px] h-[60px] z-40 relative text-white bg-[#FF0000] rounded-full cursor-pointer"
            onClick={() => handleToggleDisplayNotify('late')}
          >
            <ClockIcon className="absolute -translate-x-1/2 -translate-y-1/2 w-9 h-9 hamburger-default top-1/2 left-1/2" />
          </div>
          <div className="bg-white z-10 absolute left-[50px] bottom-2">
            {/* items Stacking temp */}
            {infoPatientLate.length > 1 &&
              isStackingNotifyLate &&
              infoPatientLate.map((_, index) => (
                <div
                  onClick={() => handleToggleStyleNotify('late')}
                  key={index}
                  className={`bg-white w-[450px] h-[75px] shadow-md rounded-sm py-3 px-4 border border-gray-300 transition-all absolute ${
                    isDisplayNotifyLate ? '' : 'hidden'
                  }`}
                  style={{
                    bottom: `${infoPatientLate.length * 6 - (index + 1) * 6}px`,
                  }}
                />
              ))}

            {infoPatientLate.map((infoPatientLateItem, index) => (
              <div
                key={v4()}
                className={`relative ${isDisplayNotifyLate ? '' : 'hidden'}`}
              >
                <div
                  onClick={() => handleToggleStyleNotify('late')}
                  className={`bg-white w-[450px] h-[75px] shadow-md rounded-sm py-3 px-4 border border-gray-300 transition-all ${
                    isStackingNotifyLate
                      ? index === infoPatientLate.length - 1
                        ? 'block'
                        : 'hidden'
                      : ''
                  }`}
                >
                  <div className="flex items-center h-full gap-x-7">
                    <div className="min-w-[120px]">
                      <p className="text-[#D35273] bg-[#FAEAEE] px-4 rounded-md font-bold items-center flex whitespace-nowrap justify-center">
                        <span className="text-xl">
                          {displayDelayTime(infoPatientLateItem)}
                        </span>
                        <span className="text-base whitespace-nowrap">
                          {t('labels.Top.TodayTask.minutes_delay')}
                        </span>
                      </p>
                      <p className="mt-2 font-medium text-[#333] text-base">
                        {shortTimeFormatJp(
                          new Date(infoPatientLateItem?.reservation?.beginAt),
                        )}
                        〜
                        {shortTimeFormatJp(
                          new Date(infoPatientLateItem?.reservation?.endAt),
                        )}
                      </p>
                    </div>
                    <div>
                      <div className="flex items-center">
                        <div className="ml-3">
                          <p className="text-xs w-[135px] line-clamp-1 h-4 break-all text-[#333]">
                            {infoPatientLateItem?.patient?.nameKana}
                          </p>
                          <p className="font-bold w-[135px] line-clamp-1 break-all text-[#333]">
                            {infoPatientLateItem?.patient?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`btn absolute z-10 self-end bottom-3 right-[18px] ${
                    isStackingNotifyLate
                      ? index === infoPatientLate.length - 1
                        ? 'block'
                        : 'hidden'
                      : ''
                  }`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      handleShowPopupMessage(
                        infoPatientLateItem?.medicalRecordId,
                      )
                    }
                    className="px-2 py-[5px] text-base text-[#002EA8] bg-white border border-[#002EA8] rounded-full w-28 bottom font-semibold"
                  >
                    {t('labels.Top.TodayTask.delay_contact')}
                  </button>
                </div>

                <div
                  className={`bg-white absolute z-20 w-[468px] shadow-md rounded-sm p-2 border border-gray-300 ${index} ${
                    openPopupMessage === infoPatientLateItem?.medicalRecordId
                      ? 'visible bottom-0 left-[460px]'
                      : 'hidden'
                  } `}
                >
                  <div className="flex justify-between">
                    <div className="flex items-end text-base font-semibold">
                      {t('labels.Top.TodayTask.send_a_message')}{' '}
                      <p className="ml-1 text-gray-710 text-sm font-light">
                        ※200文字まで
                      </p>
                    </div>
                    <div onClick={() => setOpenPopupMessage('')}>
                      <XMarkIcon className="w-5 h-5 cursor-pointer" />
                    </div>
                  </div>
                  <form
                    className="flex justify-between mt-2"
                    onSubmit={handleSubmit(handleSendMessage)}
                  >
                    <div>
                      <TextareaControl
                        control={control}
                        name="message"
                        rows={2}
                        className="max-w-lg shadow-sm block sm:text-sm border border-gray-300 rounded-md focus:outline-none resize-none w-[350px] p-1"
                        placeholder={`${DEFAULT_DELAY_MESSAGE}`}
                      />
                    </div>
                    <div className="self-end mb-2">
                      <button
                        type="submit"
                        className={`w-20 px-2 py-[5px] text-base text-white bg-[#002EA8] border rounded-full ${
                          isSubmitting && 'cursor-not-allowed'
                        }`}
                        disabled={isLoadingDelayExamination}
                      >
                        {t('labels.Top.TodayTask.send')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {infoPatientEarly && infoPatientEarly.length > 0 && (
        <div className="fixed z-30 bottom-2">
          <div
            className="w-[60px] h-[60px] z-40 relative text-white bg-[#2563eb] rounded-full cursor-pointer"
            onClick={() => handleToggleDisplayNotify('early')}
          >
            <ClockIcon className="absolute -translate-x-1/2 -translate-y-1/2 w-9 h-9 hamburger-default top-1/2 left-1/2" />
          </div>
          <div className="bg-white z-10 absolute left-[50px] bottom-2">
            {/* items Stacking temp */}
            {infoPatientEarly.length > 1 &&
              isStackingNotifyEarly &&
              infoPatientEarly.map((_: any, index: number) => (
                <div
                  onClick={() => handleToggleStyleNotify('early')}
                  key={index}
                  className={`bg-white w-[450px] h-[75px] shadow-md rounded-sm py-3 px-4 border border-gray-300 transition-all absolute ${
                    isDisplayNotifyEarly ? '' : 'hidden'
                  }`}
                  style={{
                    bottom: `${
                      infoPatientEarly.length * 6 - (index + 1) * 6
                    }px`,
                  }}
                />
              ))}

            {infoPatientEarly.map(
              (infoPatientEarlyItem: any, index: number) => (
                <div
                  key={v4()}
                  className={`relative ${isDisplayNotifyEarly ? '' : 'hidden'}`}
                >
                  <div
                    onClick={() => handleToggleStyleNotify('early')}
                    className={`bg-white w-[450px] h-[75px] shadow-md rounded-sm py-3 px-4 border border-gray-300 transition-all ${
                      isStackingNotifyEarly
                        ? index === infoPatientEarly.length - 1
                          ? 'block'
                          : 'hidden'
                        : ''
                    }`}
                  >
                    <div className="flex items-center h-full gap-x-7">
                      <div className="min-w-[120px]">
                        <p className="px-4 font-bold text-orange-500 rounded-sm bg-orange-50 whitespace-nowrap text-center">
                          {t('labels.Top.TodayTask.remaining')}
                          <span className="text-xl">
                            {estimatedTime(
                              new Date(),
                              new Date(
                                infoPatientEarlyItem?.reservation?.beginAt,
                              ),
                            )}
                          </span>
                          {t('labels.Top.TodayTask.minutes')}
                        </p>
                        <p className="mt-2 font-medium text-[#333] text-base">
                          {shortTimeFormatJp(
                            new Date(
                              infoPatientEarlyItem?.reservation?.beginAt,
                            ),
                          )}
                          〜
                          {shortTimeFormatJp(
                            new Date(infoPatientEarlyItem?.reservation?.endAt),
                          )}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center">
                          <div className="ml-3">
                            <p className="text-xs w-[135px] line-clamp-1 h-4 break-all">
                              {infoPatientEarlyItem?.patient?.nameKana}
                            </p>
                            <p className="font-bold w-[135px] line-clamp-1 break-all">
                              {infoPatientEarlyItem?.patient?.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`btn absolute z-10 self-end bottom-3 right-[18px] ${
                      isStackingNotifyEarly
                        ? index === infoPatientEarly.length - 1
                          ? 'block'
                          : 'hidden'
                        : ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        handleShowPopupMessage(
                          infoPatientEarlyItem?.medicalRecordId,
                        )
                      }
                      className="px-2 py-[5px] text-base text-[#002EA8] bg-white border border-[#002EA8] rounded-full w-28 bottom font-semibold"
                    >
                      {t('labels.Top.TodayTask.delay_contact')}
                    </button>
                  </div>

                  <div
                    className={`bg-white absolute z-20 w-[468px] shadow-md rounded-sm p-2 border border-gray-300 ${index} ${
                      openPopupMessage === infoPatientEarlyItem?.medicalRecordId
                        ? 'visible bottom-0 left-[460px]'
                        : 'hidden'
                    } `}
                  >
                    <div className="flex justify-between">
                      <div className="flex items-end text-base font-semibold">
                        {t('labels.Top.TodayTask.send_a_message')}{' '}
                        <p className="ml-1 text-gray-710 text-sm font-light">
                          ※200文字まで
                        </p>
                      </div>
                      <div onClick={() => setOpenPopupMessage('')}>
                        <XMarkIcon className="w-5 h-5 cursor-pointer" />
                      </div>
                    </div>
                    <form
                      className="flex justify-between mt-2"
                      onSubmit={handleSubmit(handleSendMessage)}
                    >
                      <div>
                        <TextareaControl
                          control={control}
                          name="message"
                          rows={2}
                          className="max-w-lg shadow-sm block sm:text-sm border border-gray-300 rounded-md focus:outline-none resize-none w-[350px] p-1"
                          placeholder={`${DEFAULT_DELAY_MESSAGE}`}
                        />
                      </div>
                      <div className="self-end mb-2">
                        <button
                          type="submit"
                          className={`w-20 px-2 py-[5px] text-base text-white bg-[#002EA8] border rounded-full ${
                            isSubmitting && 'cursor-not-allowed'
                          }`}
                          disabled={isLoadingDelayExamination}
                        >
                          {t('labels.Top.TodayTask.send')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      )}
    </div>
  )
}
