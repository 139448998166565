import { API_HOST, AUTHENTICATE_TOKEN_KEY } from 'app/config'
import axios, { AxiosResponse } from 'axios'

const apiClient = axios.create({
  baseURL: API_HOST,
  headers: {},
})

function onResponse(response: AxiosResponse): AxiosResponse {
  return response.data
}
async function onRequest(config: any) {
  try {
    const accessToken = JSON.parse(
      window.localStorage.getItem(AUTHENTICATE_TOKEN_KEY) || '',
    )
    if (accessToken) {
      const authorizationToken = `Bearer ${accessToken?.idToken}`
      config.headers.Authorization = authorizationToken
    }
    // eslint-disable-next-line
  } catch (e) {}

  return config
}

apiClient.interceptors.request.use(onRequest)
apiClient.interceptors.response.use(onResponse)

export const setHeader = (authToken: any) => {
  if (!authToken) {
    return
  }
  apiClient.defaults.headers.common.Authorization = `${authToken.token_type} ${authToken.idToken}`
}

if (window.localStorage.getItem(AUTHENTICATE_TOKEN_KEY)) {
  const authToken = JSON.parse(
    window.localStorage.getItem(AUTHENTICATE_TOKEN_KEY) || '',
  )
  setHeader(authToken)
}

export { apiClient }
