import {
  KIND_OF_DELIVERY_OUTSIDE,
  KIND_OF_PRESCRIPTION,
  MAX_SIZE_UPLOAD,
  MAX_SIZE_UPLOAD_IMAGE,
  MEDICAL_EXAMINATION_DEPARTMENTS,
  MEDICAL_EXAMINATION_SECTIONS,
  MEDICAL_EXAMINATION_STATUS,
  MEDICAL_EXAMINATION_STATUS_DEFINITION,
  MEGABYTE,
  PERMISSIONS,
} from 'app/config/app'
import { toast } from 'react-toastify'
import { Task } from 'types/Task'
import { MedicalRecordIdsUpdatedType } from 'features/common/commonSlice'
import { ConsultationHoursExisting } from 'types/Consultation'
import { Department } from 'types/Department'
import { MedicalRecordOfPatient } from 'types/MedicalRecordOfPatient'
import { Questionnaire } from 'types/Questionnaire'
import { Staff } from 'types/Staff'
import { fulldateWeekFormat } from './datetime'

export const toString = () => 'toString'

export const resolvePermissionLabel = (permission: string) =>
  PERMISSIONS[permission] || ''

export const resolveExaminationDepartment = (value: string) =>
  MEDICAL_EXAMINATION_DEPARTMENTS[value]

export const checkMedicalExaminationFlag = (status: string) => {
  const endingStatus = [
    MEDICAL_EXAMINATION_STATUS.UNDER_CONSULTATION,
    MEDICAL_EXAMINATION_STATUS.COMPLETED,
    MEDICAL_EXAMINATION_STATUS.CANCELED,
  ]

  if (endingStatus.includes(status)) {
    return null
  }

  return MEDICAL_EXAMINATION_STATUS_DEFINITION[status]
}

export const checkMedicalExaminationStatus = (status: string) => {
  const waitingConsultation = [
    MEDICAL_EXAMINATION_STATUS.FACE_TO_FACE,
    MEDICAL_EXAMINATION_STATUS.ENTERING_MEDICAL_DOCUMENTS,
    MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION_STARTED,
  ]

  const awaitingAccountingNotification = [
    MEDICAL_EXAMINATION_STATUS.SELECTING_PAYMENT_METHOD,
    MEDICAL_EXAMINATION_STATUS.DURING_ACCOUNTING_PAYPAY,
    MEDICAL_EXAMINATION_STATUS.ERROR_SETTLEMENT,
  ]

  const awaitingDeliveryToHome = [
    MEDICAL_EXAMINATION_STATUS.AWAITING_DELIVERY_TO_PHARMACY,
    MEDICAL_EXAMINATION_STATUS.AWAITING_DELIVERY_TO_HOME,
    MEDICAL_EXAMINATION_STATUS.ENTERING_PHARMACY_INFORMATION,
  ]

  if (waitingConsultation.includes(status)) {
    return MEDICAL_EXAMINATION_STATUS_DEFINITION.WAITING_CONSULTATION
  }
  if (awaitingAccountingNotification.includes(status)) {
    return MEDICAL_EXAMINATION_STATUS_DEFINITION.AWAITING_ACCOUNTING_NOTIFICATION
  }
  if (awaitingDeliveryToHome.includes(status)) {
    return MEDICAL_EXAMINATION_STATUS_DEFINITION.AWAITING_DELIVERY_TO_HOME
  }

  return MEDICAL_EXAMINATION_STATUS_DEFINITION[status]
}

export const checkStatusColor = (status: any) => {
  const mappingStatusColors: any = {
    診察待ち: '#d0edf9',
    会計待ち: '#f6f9d0',
    発送待ち: '#d2f9d0',
    診察中: '#d0ddf9',
    完了: '#d8d0f9',
    診察取消: '#e2e3e3',
  }
  return mappingStatusColors[status] || '#D8D0F9'
}

const reservationStatus = [
  MEDICAL_EXAMINATION_STATUS.FACE_TO_FACE,
  MEDICAL_EXAMINATION_STATUS.WAITING_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.ENTERING_MEDICAL_DOCUMENTS,
  MEDICAL_EXAMINATION_STATUS.NOT_CONSULTATION_STARTED,
  MEDICAL_EXAMINATION_STATUS.UNDER_CONSULTATION,
  MEDICAL_EXAMINATION_STATUS.CANCELED,
]

const consultationStatus = [
  MEDICAL_EXAMINATION_STATUS.SELECTING_PAYMENT_METHOD,
  MEDICAL_EXAMINATION_STATUS.AWAITING_ACCOUNTING_NOTIFICATION,
  MEDICAL_EXAMINATION_STATUS.DURING_ACCOUNTING_PAYPAY,
  MEDICAL_EXAMINATION_STATUS.ERROR_SETTLEMENT,
  MEDICAL_EXAMINATION_STATUS.ENTERING_PHARMACY_INFORMATION,
  MEDICAL_EXAMINATION_STATUS.AWAITING_DELIVERY_TO_PHARMACY,
  MEDICAL_EXAMINATION_STATUS.AWAITING_DELIVERY_TO_HOME,
  MEDICAL_EXAMINATION_STATUS.COMPLETED,
]

export const checkLatestMedicalRecordStatus = (
  status: string,
  reservationBeginAt: string,
  consultationBeginAt: string,
) => {
  if (reservationStatus.includes(status)) {
    return fulldateWeekFormat(reservationBeginAt)
  }
  if (consultationStatus.includes(status)) {
    return fulldateWeekFormat(consultationBeginAt)
  }
}

export const sortMedicalRecords = (items: any[]) => {
  return items.sort((prev: any, next: any) => {
    const nextDateCompare = next.latestMedicalRecord.reservation.beginAt
    const prevDateCompare = prev.latestMedicalRecord.reservation.beginAt
    return nextDateCompare.toString().localeCompare(prevDateCompare)
  })
}

export const displayAddress = (address: any) => {
  return `〒${address?.postalCode1 ?? ''}-${address?.postalCode2 ?? ''}  ${
    address?.prefecture ?? ''
  }${address?.city ?? ''}${address?.street ?? ''}  ${address?.building ?? ''}`
}

export const displayDeliveryAddress = (address: any) => {
  return `${displayAddress(address)}
${address?.name1 ?? ''} ${address?.name2 ?? ''} 様
TEL ${address?.tel ?? ''}`
}

export const filterListTask = (
  data: any,
  statusFilter: any,
  sortBy: string,
) => {
  const sortByTimeOfStatus = sortBy.split('.')
  const itemOfStatus = data
    .filter((item: any) => statusFilter.includes(item.status))
    .sort(
      (a: any, b: any) =>
        new Date(
          a[sortByTimeOfStatus?.[0]]?.[sortByTimeOfStatus?.[1]],
        ).getTime() -
        new Date(
          b[sortByTimeOfStatus?.[0]]?.[sortByTimeOfStatus?.[1]],
        ).getTime(),
    )
  return itemOfStatus
}

export const filterMedicalHistory = (data: any) => {
  const itemOfMedicalHistory = data.sort(
    (a: MedicalRecordOfPatient, b: MedicalRecordOfPatient) => {
      const aTime = reservationStatus.includes(a.status)
        ? new Date(a.reservation.beginAt).getTime()
        : new Date(a.consultation.beginAt).getTime()
      const bTime = reservationStatus.includes(b.status)
        ? new Date(b.reservation.beginAt).getTime()
        : new Date(b.consultation.beginAt).getTime()
      return bTime - aTime
    },
  )
  return itemOfMedicalHistory
}

export const checkNameKana = (nameKana: string) =>
  nameKana ? `（${nameKana}）` : ''

export const formatMonthDate = (value: any) => `${value}`.slice(-2)

export const checkBirthday = (birthday: string, age: string) => {
  const birthdayFormat = new Date(birthday)
  return birthday
    ? `${birthdayFormat.getFullYear()}年${
        birthdayFormat.getMonth() + 1
      }月${birthdayFormat.getDate()}日 (${age})`
    : ''
}

export const checkMedicalRecordShippingToPharamacy = (medicalRecords: any) => {
  function handleCheckMedicalRecordShippingToPharmacy(
    medicalRecord: MedicalRecordOfPatient,
  ) {
    return (
      medicalRecord?.accounting?.prescription?.kind ===
        KIND_OF_PRESCRIPTION.OUTSIDE &&
      (medicalRecord?.accounting?.prescription?.delivery?.kind ===
        KIND_OF_DELIVERY_OUTSIDE.MAIL_TO_PHARMACY ||
        medicalRecord?.accounting?.prescription?.delivery?.kind ===
          KIND_OF_DELIVERY_OUTSIDE.LETTER_PACK_PHARMACY) &&
      medicalRecord?.accounting?.prescription?.delivery?.name &&
      medicalRecord?.accounting?.prescription?.delivery?.tel &&
      medicalRecord?.accounting?.prescription?.delivery?.address
    )
  }

  if (medicalRecords.length > 0) {
    return medicalRecords.filter((medicalRecord: any) =>
      handleCheckMedicalRecordShippingToPharmacy(medicalRecord),
    )
  }
}

export const sortStaffs = (items: Staff[]) => {
  return items.sort((prev: any, next: any) => {
    const nextDateCompare = next.createdAt
    const prevDateCompare = prev.createdAt
    return nextDateCompare.toString().localeCompare(prevDateCompare)
  })
}

export const checkTimeColor = (beginAt: any) => {
  const nowDate = new Date()
  return nowDate.getTime() - new Date(beginAt).getTime() > 0
    ? 'text-[#D35273]'
    : ''
}

export const sortConsultationHoursExisting = (
  items: ConsultationHoursExisting[],
) => {
  return items.sort((prev: any, next: any) => {
    const nextDateCompare = next.date
    const prevDateCompare = prev.date
    return prevDateCompare.toString().localeCompare(nextDateCompare)
  })
}

export const sortQuestionnaire = (
  firstSortOrderArray: any,
  secondSortOrderArray: any,
) => {
  const result: string[] = []
  firstSortOrderArray.forEach((value: any) => {
    if (secondSortOrderArray.includes(value)) {
      result.push(value)
    }
  })
  return result
}

export const formatPhoneNumberToDash = (phoneNumber: string) => {
  if (!phoneNumber) return ''
  if (phoneNumber.length > 10)
    return phoneNumber.replace(/^(\d{1,3})(\d{4})(\d{4}).*/, '$1-$2-$3')
  if (phoneNumber.startsWith('03') || phoneNumber.startsWith('06')) {
    return phoneNumber.replace(/^(\d{2})(\d{4})(\d{4}).*/, '$1-$2-$3')
  }
  if (phoneNumber.startsWith('0120')) {
    return phoneNumber.replace(/^(\d{4})(\d{3})(\d{3}).*/, '$1-$2-$3')
  }
  return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1-$2-$3')
}

export const isBase64Pdf = (fileData: string) => {
  const pdfBase64Regex = /^((data:application\/(pdf));base64[^"]+)$/
  return pdfBase64Regex.test(fileData)
}

export const removeSpecialCharacters = (str: string) => {
  if (!str) return ''
  return str.replace(/[^\w\s]/gi, '')
}

export const convertFullToHalf = (string: string) => {
  return string.replace(/[！-～]/g, (halfWidthChar: any) =>
    String.fromCharCode(halfWidthChar.charCodeAt(0) - 0xfee0),
  )
}
export const getVisiblePages = (page: any, total: number) => {
  if (page < 7) {
    return [1, 2, 3, 4, 5, 6].filter(number => number <= page)
  }
  if (total % 5 >= 0 && total > 4 && total + 2 < page) {
    return [1, total - 1, total, total + 1, page]
  }
  if (total % 5 >= 0 && total > 4 && total + 2 >= page) {
    return [1, page - 3, page - 2, page - 1, page]
  }
  return [1, 2, 3, 4, 5, page]
}

export const showMessageError = (error: any, debug: boolean = false) => {
  const message = error?.message || error?.response?.data?.message || ''
  /* eslint-disable-next-line no-console */
  console.error(error)
  toast.error(debug ? JSON.stringify(message) : 'エラーが発生しました')
}

export const numberToFixedItem = (step: string) => step.split('.')[1].length

export const sortSection = (sections: any) => {
  return sections.sort(
    (a: string, b: string) =>
      MEDICAL_EXAMINATION_SECTIONS.indexOf(a) -
      MEDICAL_EXAMINATION_SECTIONS.indexOf(b),
  )
}

export const shouldSubmitDisabled = (isDisabled?: boolean) =>
  isDisabled ? `bg-[#9CA3AF] cursor-not-allowed` : 'bg-blue-610 border-blue-610'

export const isTab = (tabClick: string, type: string) => {
  return tabClick === type ? 'block' : 'hidden'
}

export const isNumberGreaterThanZeroAndNotNaN = (value: any) => {
  // eslint-disable-next-line no-restricted-globals
  return typeof value === 'number' && value > 0 && !isNaN(value)
}

export const isShowOrangeDot = (
  medicalRecord: Task,
  medicalRecordIdsUpdated: MedicalRecordIdsUpdatedType[],
) => {
  const medicalRecordIds = medicalRecordIdsUpdated.map(
    (item: MedicalRecordIdsUpdatedType) => item.id,
  )

  const result = medicalRecordIds.includes(medicalRecord.medicalRecordId)
  return result
}

export const SORT_ORDER = (arr: Department[]) => {
  arr.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
  return arr
}

export const updateRecords = <T extends { id?: string; key?: string }>(
  prev: T[],
  newRecords: T[],
  compareFields: (a: T, b: T) => boolean,
): T[] => {
  const updatedRecords = prev.map(record => {
    const newRecord = newRecords.find(newRec => compareFields(newRec, record))
    return newRecord || record
  })

  const newRecordsToAdd = newRecords.filter(
    newRec => !prev.some(record => compareFields(newRec, record)),
  )

  return [...updatedRecords, ...newRecordsToAdd]
}

export const SORT_DEPARTMENT = (arrDepartment: Department[]) => {
  const sectionDepartment = arrDepartment.filter(item => {
    return item.category === 'SECTION' && item.insuranceCategory === 'INSURANCE'
  })
  const medicineDepartment = arrDepartment.filter(item => {
    return (
      item.category === 'MEDICINE' && item.insuranceCategory === 'INSURANCE'
    )
  })
  const insuranceUseDepartment = [
    ...SORT_ORDER(sectionDepartment),
    ...SORT_ORDER(medicineDepartment),
  ]
  const sectionCostsUseDepartment = arrDepartment.filter(item => {
    return item.category === 'SECTION' && item.insuranceCategory !== 'INSURANCE'
  })
  const medicineCostsUseDepartment = arrDepartment.filter(item => {
    return (
      item.category === 'MEDICINE' && item.insuranceCategory !== 'INSURANCE'
    )
  })
  const costsUseDepartment = [
    ...SORT_ORDER(sectionCostsUseDepartment),
    ...SORT_ORDER(medicineCostsUseDepartment),
  ]
  return [...insuranceUseDepartment, ...costsUseDepartment]
}

export const SORT_QUESTIONNAIRE = (arr: Questionnaire[]) => {
  arr.sort(
    (a: Questionnaire, b: Questionnaire) =>
      Date.parse(a.createdAt) - Date.parse(b.createdAt),
  )
  return arr
}
export const checkMaxSizeUploadFileImage = (file: any) =>
  file.size / MEGABYTE > MAX_SIZE_UPLOAD_IMAGE

export const checkMaxSizeUploadFile = (file: any) =>
  file.size / MEGABYTE > MAX_SIZE_UPLOAD

export const formatPriceJapan = (price: number) => {
  const formattedPrice = price.toLocaleString('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  })
  const formattedPriceReplace = formattedPrice.replace('￥', '')
  return `${formattedPriceReplace}円`
}
