// import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AUTHENTICATE_INFO } from 'app/config'
import { tabs } from 'app/config/app'
import { ROUTES } from 'app/config/routes'
import { useAppDispatch } from 'app/hooks'
import {
  setAccessToken,
  setCurrentUser,
  setIsAuthenticated,
} from 'features/auth/authSlice'
import { setLastRunTime } from 'features/lastRunTime/lastRunTimeSlice'
import { setTab } from 'features/switchTab/switchTabSlice'
import { setSubTabChoice } from 'features/tabReducer/tabReducerSlice'
import { Fragment, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props extends SvgProps {}

interface SvgProps {
  open?: boolean
  setOpen: (value: boolean) => void
}

export function LogoutModal(props: Props) {
  const cancelButtonRef = useRef(null)
  const { t } = useTranslation()
  const { open, setOpen } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  let shouldNavigation = true

  const handleLogout = async () => {
    dispatch(setCurrentUser(null))
    dispatch(setAccessToken(null))
    dispatch(setIsAuthenticated(false))
    dispatch(setTab(tabs.TOP))
    dispatch(setSubTabChoice(tabs.BASIC_SETTINGS))
    dispatch(setLastRunTime(''))
    try {
      AUTHENTICATE_INFO.forEach(item => window.localStorage.removeItem(item))
    } catch {
      shouldNavigation = true
    }
    if (shouldNavigation) {
      return navigate(ROUTES.AUTH.LOGIN)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="top-[380px] left-[680px] width-[560px] height-[320px] bg-[#ffffff] rounded-[5px] opacity-100">
                <div className="pt-[108px]">
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold text-gray-900"
                    >
                      {t('labels.logout.messageConfirm')}
                    </Dialog.Title>
                  </div>
                </div>

                <div className="top-[380px] left-[680px] width-[560px] height-[320px] bg-[#ffffff] rounded-[5px] pt-[60px] pb-[71px]">
                  <button
                    type="button"
                    className="top-[597px] left-[780px] ml-[100px] mr-12 w-[160px] h-[38px] py-1 rounded-[24px] text-[20px] text-white bg-blue-610"
                    ref={cancelButtonRef}
                    onClick={() => setOpen(false)}
                  >
                    {t('labels.logout.btnCancel')}
                  </button>
                  <button
                    type="button"
                    className="top-[597px] left-[988px] mr-[100px] w-[160px] py-1 h-[38px] rounded-[24px] text-[20px] text-white bg-blue-610"
                    onClick={handleLogout}
                  >
                    {t('labels.logout.btnConfirm')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

LogoutModal.defaultProps = {
  open: false,
}
