import axios from 'axios'
import { apiClient } from './api'

export const postFileImage = async (data: any) => {
  const file: any = await apiClient.post(`/api/v1/files/upload`, data)
  return file
}

export const postRotateImage = async (
  patientId: string | undefined,
  data: { type: number; file: any },
) => {
  const response: { url: string; fileName: string } = await apiClient.post(
    `/api/v1/files/${patientId}/rotate`,
    data,
  )
  return response
}

export const postGeneratePresignedUrl = async (
  type: number,
  data: { fileType: string },
) => {
  const response: { url: string; fileName: string } = await apiClient.post(
    `/api/v1/files/${type}/generate-presigned-url`,
    data,
  )
  return response
}

export const putGeneratePresignedUrl = async (url: string, file: File) => {
  const response: any = await axios({
    url,
    method: 'PUT',
    data: file,
    headers: {
      'Content-Type': file.type,
    },
  })
  return response
}

export const getFileImage = async (fileName: any) => {
  const url: any = await apiClient.get(
    `/api/v1/files/${encodeURIComponent(fileName)}`,
  )
  return url
}

export const putUploadImageBinary = async (
  url: string,
  fileType: string,
  binaryString: string | ArrayBuffer | File,
): Promise<any> =>
  (await axios({
    method: 'PUT',
    url,
    data: binaryString,
    headers: {
      'Content-Type': fileType,
    },
  })) ?? {}
