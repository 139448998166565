import React from 'react'
// import { MeetingProvider } from 'amazon-chime-sdk-component-library-react'
// import { meetingConfigValue } from 'app/config/meeting'
import { MeetingProviderWrapper } from 'providers/MeetingProviderWrapper'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import { AppStateProvider } from './providers/AppStateProvider'
import { store } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
// Initialize languages
import './locales/i18n'
import './index.css'
import 'react-tooltip/dist/react-tooltip.css'
import { demoLightTheme, demoDarkTheme } from './theme/demoTheme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

const container = document.getElementById('root')!
const root = createRoot(container)
const theme: any = 'light'

function StrictMode(props: any) {
  const { children } = props
  const enableStrict = !true
  return enableStrict ? (
    <React.StrictMode>{children}</React.StrictMode>
  ) : (
    // eslint-disable-next-line
    <React.Fragment>{children}</React.Fragment>
  )
}

root.render(
  <StrictMode>
    <AppStateProvider>
      <HelmetProvider>
        <BrowserRouter>
          <Provider store={store}>
            <ThemeProvider
              theme={theme === 'light' ? demoLightTheme : demoDarkTheme}
            >
              <MeetingProviderWrapper>
                {/* <MeetingProvider {...meetingConfigValue}> */}
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
                <ToastContainer autoClose={5000} hideProgressBar />
                {/* </MeetingProvider> */}
              </MeetingProviderWrapper>
            </ThemeProvider>
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    </AppStateProvider>
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
