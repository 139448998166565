import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

const initialState: any = {
  isRang: false,
}

export const telephoneRangSlice = createSlice({
  name: 'telephone_rang',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setIsTelephoneRang: (state, action: PayloadAction<boolean>) => {
      state.isRang = action.payload
    },
  },
})

export const { setIsTelephoneRang } = telephoneRangSlice.actions

export const telephoneRang = (state: RootState) => state.telephone_rang.isRang

export default telephoneRangSlice.reducer
