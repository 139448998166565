import { NO_ANSWER } from 'app/config/app'
import { ItemWithQuestionBMI } from '../../types'
import { calculateBMI } from '../../utils/formula'

export function InputItemBMI({
  item,
}: {
  item: ItemWithQuestionBMI
  index: number
}) {
  const resolveBMI = () => {
    const { height, weight } = item.questionItem.question.bmiQuestion
    if (!height || !weight) return NO_ANSWER
    return calculateBMI(height, weight)
  }
  const bmiQuestions = [
    {
      name: '身長（cm）: ',
      value: item.questionItem.question.bmiQuestion.height || NO_ANSWER,
    },
    {
      name: '体重（kg）: ',
      value: item.questionItem.question.bmiQuestion.weight || NO_ANSWER,
    },
    {
      name: 'BMI: ',
      value: resolveBMI(),
    },
  ]

  return (
    <>
      {bmiQuestions.map((bmiQuestion: any, index: any) => (
        <section
          key={index}
          className="relative text-[14px] flex gap-2 items-center mt-1 first:mt-0"
        >
          <label className="leading-[17px] inline-block text-[#3772D0]">
            {bmiQuestion.name}
          </label>
          <div className="text-[14px] leading-[17px] font-light break-words text-gray-710">
            <div className="index-17">{bmiQuestion.value}</div>
          </div>
        </section>
      ))}
    </>
  )
}
