import { STORAGE_DOCUMENT_PATHS_TYPES } from 'app/config/app'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  getFileImage,
  postRotateImage,
  putUploadImageBinary,
} from 'app/services/file'
import {
  selectKeepImageRotated,
  setKeepImageRotated,
} from 'features/imageRotate/imageRotateSlice'
import {
  InfoImageQuestionnaire,
  selectListImageQuestionnaire,
  setListImageQuestionnaire,
} from 'features/tabReducer/tabReducerSlice'
import { useKeypress } from 'hooks/usePessESCKey'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TypeKeepImageRotated } from 'types/Patient'
import { showMessageError } from 'utils/helper'
import { base64ToFile, toDataUrl, urlHeaders } from 'utils/image'
import IconExpand from '../Icons/IconExpand'
import IconMark from '../Icons/IconMark'
import IconMinus from '../Icons/IconMinus'
import IconNext from '../Icons/IconNext'
import IconPlus from '../Icons/IconPlus'
import IconPrev from '../Icons/IconPrev'
import IconRotateLeft from '../Icons/IconRotateLeft'
import IconTick from '../Icons/IconTick'

interface Props {
  openPopup?: boolean
  setOpenPopup: (value: boolean) => void
  popupId: string | undefined
  setQuestionnaireResponseId?: Dispatch<SetStateAction<string>>
  setItemQuestionnaireId?: Dispatch<SetStateAction<string>>
  type: number
  itemQuestionnaireId?: string
  questionnaireResponseId?: string
  image: any
  alt: string
  indexOriginImage?: any
  indexImageCurrent?: any
  setIndexOrigin?: Dispatch<SetStateAction<number | undefined>>
  setIndexImageCurrent?: any
}

export function PopupZoomRotateImage(props: Props) {
  const {
    openPopup,
    setOpenPopup,
    popupId,
    setQuestionnaireResponseId,
    setItemQuestionnaireId,
    type,
    itemQuestionnaireId,
    questionnaireResponseId,
    image,
    indexOriginImage,
    indexImageCurrent,
    setIndexOrigin,
    setIndexImageCurrent,
    alt,
  } = props

  const dispatch = useAppDispatch()

  const [rotationDegree, setRotationDegree] = useState<number>(0)
  const { patientId } = useParams<{ patientId: string }>()
  const [scaleZoom, setScaleZoom] = useState<number>(1)
  const [isLoadingRotation, setIsLoadingRotation] = useState<boolean>(false)
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false)
  const [imagePopup, setImagePopup] = useState<any>(null)
  const [originImage, setOriginImage] = useState<any>(null)
  const [imageRotated, setImageRotated] = useState<string>('')
  const [isChangeImageRotated, setIsChangeImageRotated] =
    useState<boolean>(false)
  const listImageQuestionnaire = useAppSelector(selectListImageQuestionnaire)
  const keepImageRotated = useAppSelector(selectKeepImageRotated)

  useEffect(() => {
    if (image) {
      const url: string | string[] = image

      setImagePopup(url)
      setOriginImage(url)
    }
  }, [image])

  const countRow = 1
  const conditionIsArrayImage = Array.isArray(imagePopup)
    ? imagePopup.length
    : -1

  const isArrayOriginImage = Array.isArray(originImage)

  const conditionNext = indexImageCurrent + countRow < conditionIsArrayImage

  const imageRef = useRef<any>(null)
  const imageRefs = useRef<any>([])

  const shouldDisabledRotate = isLoadingRotation || isLoadingImage

  const handleRotate = () => {
    if (shouldDisabledRotate) return
    setIsLoadingRotation(true)
    setIsChangeImageRotated(true)
    setRotationDegree(prevCountRotate => (prevCountRotate - 90) % 360)
  }

  const handleZoomIn = () => {
    setScaleZoom(prevCountZoom => {
      return prevCountZoom + 0.1
    })
  }
  const handleZoomOut = () => {
    setScaleZoom(prevCountZoom => {
      if (prevCountZoom <= 0.5) return prevCountZoom
      return prevCountZoom - 0.1
    })
  }

  const resetIndexOrigin = () => {
    if (setIndexOrigin) {
      setIndexOrigin(-1)
    }
  }

  const inspectImageRefAndRefs = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.style.top = 0
      imageRef.current.style.left = 0
    }

    if (imageRefs && imageRefs.current[indexImageCurrent]) {
      const imageRefCurrent = imageRefs.current[indexImageCurrent]

      imageRefCurrent.style.top = 0
      imageRefCurrent.style.left = 0
    }
  }

  const updateDataRotatedUrl = async (
    dataRotatedUrl: string,
  ): Promise<string> => {
    return new Promise<string>(resolve => {
      toDataUrl(
        dataRotatedUrl,
        (result: string) => {
          resolve(result)
        },
        urlHeaders,
      )
    })
  }

  const handleSaveRotate = async (
    imageElement: HTMLImageElement,
    deg: number,
  ) => {
    const img = new Image()
    if (image) {
      img.src = isArrayOriginImage
        ? await updateDataRotatedUrl(originImage[indexImageCurrent])
        : await updateDataRotatedUrl(originImage)
    }

    img.onload = async () => {
      const { width } = img
      const { height } = img

      const angleInRadians = (deg * Math.PI) / 180

      // Tạo canvas với kích thước của ảnh gốc
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const newDeg = Math.abs(deg)

      if (newDeg === 90 || newDeg === 270) {
        // Change width and height if rotated 90 or 270 degrees
        canvas.width = height
        canvas.height = width
      } else {
        canvas.width = width
        canvas.height = height
      }

      // Rotate and draw iamge up canvas
      ctx?.translate(canvas.width / 2, canvas.height / 2)
      ctx?.rotate(angleInRadians)
      ctx?.drawImage(img, -width / 2, -height / 2)

      img.onload = null // Remove the load event handler
      img.src = '' // Clear the src to release memory

      const dataURL: string = canvas.toDataURL('image/jpeg') // Convert image to dataURL (JPEG) with quality
      imageElement.src = dataURL
      setImageRotated(dataURL)
      setIsLoadingRotation(false)
    }
    img.onerror = () => setIsLoadingRotation(false)
  }

  const processImageRotation = (
    imageElement: HTMLImageElement,
    degree: number,
  ) => {
    if (imageElement) {
      handleSaveRotate(imageElement, degree)
    }
  }

  const groupProcessImageRotation = (degree: number) => {
    if (imagePopup?.length > 0) {
      processImageRotation(imageRef?.current, degree)

      const imageRefCurrent = imageRefs?.current[indexImageCurrent]

      processImageRotation(imageRefCurrent, degree)
    }
  }

  const handleContextMenu = (e: MouseEvent) => {
    if (isLoadingRotation) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (isLoadingRotation) {
      document.addEventListener('contextmenu', handleContextMenu)
    } else {
      document.removeEventListener('contextmenu', handleContextMenu)
    }

    // Clean up the event listener when the component unmounts or when isLoadingRotation changes
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [isLoadingRotation])

  const handleClose = () => {
    setOpenPopup(false)
    setRotationDegree(0)
    setScaleZoom(1)
    inspectImageRefAndRefs()
    resetIndexOrigin()
    setIndexImageCurrent(null)
  }

  useEffect(() => {
    if (!isChangeImageRotated && !isLoadingImage) return
    groupProcessImageRotation(rotationDegree)
  }, [rotationDegree])

  useKeypress(e => {
    if (e.key === 'Escape' && !isLoadingImage) {
      handleClose()
    }
  })

  const loadImage = (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = url

      img.onload = () => {
        resolve(img.src)
      }

      img.onerror = error => {
        reject(error)
      }
    })
  }

  const handleSaveImage = async () => {
    if (!patientId || isLoadingImage || !imageRotated || !isChangeImageRotated)
      return

    setIsLoadingImage(true)
    try {
      const newIndex =
        indexOriginImage >= 0 ? indexOriginImage : indexImageCurrent

      const extension = (imageRotated as any).match(/\/([0-9a-z]+);/i)[1]
      const fileType = `image/${extension}`

      const payload: any = {
        file: {
          index: newIndex,
          fileType,
        },
        type,
        ...(questionnaireResponseId && { questionnaireResponseId: popupId }),
        ...(itemQuestionnaireId && { itemQuestionnaireId }),
      }

      const { url: newUrl, fileName } = await postRotateImage(
        patientId,
        payload,
      )
      const formatFile = base64ToFile(imageRotated, fileName)

      if (formatFile) {
        await putUploadImageBinary(newUrl, fileType, formatFile)
      }
      const { url: urlFileName } = await getFileImage(fileName)

      let rotatedUrl: string | string[]
      let url: string = ''

      await loadImage(urlFileName).then((result: any) => {
        url = result
      })

      if (isArrayOriginImage) {
        const originRotatedUrl = image
        rotatedUrl = [...originRotatedUrl]
        rotatedUrl[indexImageCurrent] = url
      } else {
        rotatedUrl = url
      }

      // handle data questionnaire image response
      if (listImageQuestionnaire?.length > 0 && questionnaireResponseId) {
        const currentRecord: any = listImageQuestionnaire.find(
          (item: InfoImageQuestionnaire) =>
            item.key === questionnaireResponseId,
        )

        if (currentRecord) {
          const newItemResponse = currentRecord.value.response.map(
            (item: { url: string }, index: number) =>
              index === newIndex ? { ...item, url } : item,
          )

          const newRecords: InfoImageQuestionnaire = {
            ...currentRecord,
            value: { ...currentRecord.value, response: newItemResponse },
          }

          dispatch(setListImageQuestionnaire(newRecords))
        }
      }

      if (rotatedUrl) {
        setImagePopup(rotatedUrl)
        setOriginImage(rotatedUrl)
        if (keepImageRotated.length > 0 && popupId) {
          const newRecords: TypeKeepImageRotated = {
            id: popupId,
            type,
            value: rotatedUrl,
          }

          dispatch(setKeepImageRotated(newRecords))
        }
      }
    } catch (error: any) {
      showMessageError(error)
    } finally {
      setIsChangeImageRotated(false)
      setIsLoadingImage(false)
    }
  }

  const handleReset = async () => {
    setRotationDegree(0)
    setScaleZoom(1)
    inspectImageRefAndRefs()
  }

  const dragElement = (ref: HTMLElement) => {
    const imageFrame = ref
    let newPosX = 0
    let newPosY = 0
    let startPosX = 0
    let startPosY = 0

    if (!imageFrame) return

    imageFrame.addEventListener('mousedown', (e: any) => {
      e.preventDefault()

      startPosX = e.clientX
      startPosY = e.clientY

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      document.addEventListener('mousemove', mouseMove)

      document.addEventListener('mouseup', () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        document.removeEventListener('mousemove', mouseMove)
      })
    })

    const mouseMove = (e: any) => {
      newPosX = startPosX - e.clientX
      newPosY = startPosY - e.clientY

      startPosX = e.clientX
      startPosY = e.clientY

      imageFrame.style.top = `${imageFrame.offsetTop - newPosY}px`
      imageFrame.style.left = `${imageFrame.offsetLeft - newPosX}px`
    }
  }

  useEffect(() => {
    dragElement(imageRef.current as HTMLImageElement)

    // Run event default of image refs
    for (let i = 0; i < 5; i += 1) {
      dragElement(imageRefs.current[i] as HTMLImageElement)
    }
  }, [])

  const getItemQuestionnaire = (urlImageValue: string) => {
    let itemQuestionnaire: Partial<InfoImageQuestionnaire> = {
      key: '',
      itemId: '',
    }

    listImageQuestionnaire.forEach((item: any) => {
      item.value.response.forEach((response: { url: string }) => {
        if (JSON.stringify(response.url) === JSON.stringify(urlImageValue)) {
          itemQuestionnaire = { key: item.key, itemId: item.itemId }
        }
      })
    })

    return itemQuestionnaire
  }

  const handleDragResetImage = () => {
    if (
      imageRefs &&
      imageRefs?.current &&
      imageRefs.current[indexImageCurrent]
    ) {
      imageRefs.current[indexImageCurrent].src = imagePopup[indexImageCurrent]
      dragElement(imageRefs.current[indexImageCurrent] as HTMLImageElement)
    }

    handleReset()
  }

  const getQuestionnaireResponse = () => {
    const questionnaireResponses = listImageQuestionnaire.map(
      item => item?.value?.response,
    )

    return questionnaireResponses || []
  }

  const resolveIndexValue = (
    images: { url: string }[],
    urlImageValue: string,
  ) => {
    return images.findIndex(response => response.url === urlImageValue)
  }

  useEffect(() => {
    // Update the ref object when the slice array changes
    handleDragResetImage()

    if (indexImageCurrent < 0) return

    // Handle set questionnaireResponseId and itemQuestionnaireId of image
    if (setQuestionnaireResponseId && setItemQuestionnaireId) {
      const itemQuestionnaire = getItemQuestionnaire(
        imagePopup[indexImageCurrent],
      )

      if (itemQuestionnaire.key && itemQuestionnaire.itemId) {
        setQuestionnaireResponseId(itemQuestionnaire.key)
        setItemQuestionnaireId(itemQuestionnaire.itemId)
      }
    }

    // Handle set index origin of image
    if (setIndexOrigin) {
      const dataQuestionnaireResponse = getQuestionnaireResponse()

      dataQuestionnaireResponse.forEach((item: any) => {
        const findIndexValueQuestionResponse = resolveIndexValue(
          item,
          imagePopup[indexImageCurrent],
        )
        if (findIndexValueQuestionResponse > -1) {
          setIndexOrigin(findIndexValueQuestionResponse)
        }
      })
    }

    return () => {
      setImageRotated('')
      setIsChangeImageRotated(false)
    }
  }, [imagePopup, indexImageCurrent])

  // Handle next & prev
  const handleNext = () => {
    if (conditionIsArrayImage < -1 || isLoadingImage) return
    const now = conditionNext ? indexImageCurrent + countRow : 0
    setIndexImageCurrent(now)
  }

  const handlePrev = () => {
    if (conditionIsArrayImage < -1 || isLoadingImage) return
    const now =
      indexImageCurrent > 0
        ? indexImageCurrent - countRow
        : conditionIsArrayImage - countRow
    setIndexImageCurrent(now)
  }

  useKeypress(e => {
    if (imagePopup?.length > 0 && e?.key) {
      const { key } = e
      const minRange = 0
      const maxRange = imagePopup.length - 1
      const inRange =
        indexImageCurrent >= minRange && indexImageCurrent <= maxRange

      if (inRange) {
        switch (key) {
          case 'ArrowRight':
            if (indexImageCurrent < maxRange) {
              handleNext()
            }
            break
          case 'ArrowLeft':
            if (indexImageCurrent > minRange) {
              handlePrev()
            }
            break
          default:
            break
        }
      }
    }
  })

  function IconNextPrev({ isBoolean }: { isBoolean: boolean }) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {conditionIsArrayImage > 0 && (
          <div
            className={`absolute z-50 top-1/2 ${
              isBoolean ? 'left-4' : 'right-4'
            }`}
          >
            <button
              className={`bg-[#efeeee] border-b-[1px] w-12 h-12 px-3 max-h-[100vdh] ${
                isLoadingImage ? 'cursor-not-allowed' : ''
              }`}
              type="button"
              onClick={isBoolean ? handlePrev : handleNext}
            >
              {isBoolean ? <IconPrev /> : <IconNext />}
            </button>
          </div>
        )}
      </>
    )
  }

  const resolveImagePopup = () => {
    if (conditionIsArrayImage > 0) {
      return (imagePopup || image).slice(
        indexImageCurrent,
        countRow + indexImageCurrent,
      )
    }
    // Default value of image
    return [...Array(5).keys()]
  }

  return (
    <div
      className={`fixed z-40 inset-0 transition-opacity flex items-center justify-center ${
        openPopup ? 'opacity-1 visible' : 'invisible opacity-0 hidden'
      }`}
    >
      <div className="fixed inset-0 blur-[20px] z-10 bg-black/75" />
      <div className="fixed inset-0 z-10 transition-opacity bg-[#000000b3]" />

      <div className="relative z-50">
        {[
          STORAGE_DOCUMENT_PATHS_TYPES.otherDocuments,
          STORAGE_DOCUMENT_PATHS_TYPES.otherDocumentQuestionnaire,
        ].includes(type) &&
          openPopup && (
            <div
              className={conditionIsArrayImage > 0 ? 'visible' : 'invisible'}
              style={{ display: conditionIsArrayImage > 0 ? 'block' : 'none' }}
            >
              {resolveImagePopup().map((img: any, index: number) => (
                <img
                  key={img + index}
                  ref={el => {
                    if (conditionIsArrayImage > 0) {
                      imageRefs.current[indexImageCurrent] = el
                    } else {
                      imageRefs.current[index] = el
                    }
                  }}
                  src={img}
                  alt={alt}
                  className="box max-h-[80vh] max-w-[80vw] z-20 relative cursor-move"
                  style={{
                    transform: `scale(${scaleZoom})`,
                  }}
                />
              ))}
            </div>
          )}
        {conditionIsArrayImage <= 0 && (
          <img
            ref={imageRef}
            src={imagePopup || image}
            alt={alt}
            className="box max-h-[80vh] max-w-[80vw] z-20 relative cursor-move"
            style={{
              transform: `scale(${scaleZoom})`,
            }}
          />
        )}
      </div>

      {indexImageCurrent ? <IconNextPrev isBoolean /> : ''}
      {conditionNext ? <IconNextPrev isBoolean={false} /> : ''}

      <div className="absolute top-5 flex flex-col right-4 z-50">
        <button
          className={`hover:bg-[#efeeee] border-b-[1px] bg-white ${
            isLoadingImage ? 'cursor-not-allowed' : ''
          }`}
          type="button"
          disabled={isLoadingImage}
          onClick={handleClose}
        >
          <IconMark />
        </button>

        <div className="bg-white flex flex-col my-4">
          <button
            className="border-b-[1px] hover:bg-[#efeeee]"
            type="button"
            onClick={handleZoomIn}
          >
            <IconPlus />
          </button>
          <button
            className="border-b-[1px] hover:bg-[#efeeee]"
            type="button"
            onClick={handleZoomOut}
          >
            <IconMinus />
          </button>
          <button
            className={`border-b-[1px] hover:bg-[#efeeee] ${
              shouldDisabledRotate ? 'cursor-not-allowed' : ''
            }`}
            type="button"
            disabled={shouldDisabledRotate}
            onClick={handleRotate}
          >
            <IconRotateLeft />
          </button>
          <button
            className="border-b-[1px] hover:bg-[#efeeee]"
            type="button"
            onClick={handleReset}
          >
            <IconExpand />
          </button>
        </div>

        <button
          className={`border-b-[1px] hover:bg-[#efeeee] bg-white ${
            isLoadingImage ? 'cursor-not-allowed' : ''
          }`}
          type="button"
          disabled={isLoadingImage}
          onClick={handleSaveImage}
        >
          <IconTick />
        </button>
      </div>
    </div>
  )
}

PopupZoomRotateImage.defaultProps = {
  openPopup: false,
  setQuestionnaireResponseId: () => null,
  setItemQuestionnaireId: () => null,
  itemQuestionnaireId: null,
  questionnaireResponseId: null,
  indexOriginImage: -1,
  indexImageCurrent: -1,
  setIndexOrigin: () => null,
  setIndexImageCurrent: () => null,
}
