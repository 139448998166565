import common from './items/common.json'
import validation from './items/validation.json'
import pages from './items/pages.json'
import labels from './items/labels.json'

const translations = {
  common,
  validation,
  pages,
  labels,
}
export default translations
