import { NO_ANSWER } from 'app/config/app'
import { numberToFixedItem } from 'utils/helper'
import { ItemWithQuestionChoice } from '../../types'

export function InputItemDropdownRange({
  item,
  index,
}: {
  item: ItemWithQuestionChoice
  index: number
}) {
  const resolveValue = () => {
    if (!item.questionItem.question.dropdownRangeQuestion) return NO_ANSWER
    const { response, step } = item.questionItem.question.dropdownRangeQuestion
    if ((!response || !Number(response)) && Number(response) !== 0)
      return NO_ANSWER

    if (step.toString().includes('.')) {
      const fixedStep = numberToFixedItem(step.toString())
      const fixedResponse = Number(response).toFixed(fixedStep)
      return fixedResponse
    }
    return response
  }

  return <span className={`index-${index}`}>{resolveValue()}</span>
}
