import { postFileImage, getFileImage } from 'app/services/file'
import { Item, FileCameraQuestion } from '../types/index'
import { ImageItem } from '../types/Image'

export const handleLoadInputTempFiles = async (
  fileCameraQuestion: FileCameraQuestion,
) => {
  if (fileCameraQuestion.response) {
    const files: ImageItem[] = []
    const promises: any[] = []
    const fileResults: any[] = [...fileCameraQuestion.response]
    fileResults.map((file: any, index: number) => {
      const fileName = file.nameRotated || file.name

      promises.push(
        getFileImage(fileName).then(response => {
          fileResults[index] = {
            ...response,
            url: response.url,
          }
          files.push({
            index,
            file: response.url,
            name: fileName,
          })
        }),
      )
      return fileName
    })
    await Promise.all(promises)
    return { ...fileCameraQuestion, response: fileResults, files }
  }
  return fileCameraQuestion
}

export const handleUploadDataInputFiles = async (
  fileCameraQuestion: FileCameraQuestion,
) => {
  if (fileCameraQuestion.response) {
    const promises: any[] = []
    const fileResults: any[] = [...fileCameraQuestion.response]
    fileResults.map((file: any, index: number) => {
      promises.push(
        postFileImage({ file: file.file }).then(response => {
          fileResults[index] = response.fileName
        }),
      )
      return file
    })
    await Promise.all(promises)
    return { ...fileCameraQuestion, response: fileResults }
  }
  return fileCameraQuestion
}

export const handleLoadInputFiles = async (
  fileCameraQuestion: FileCameraQuestion,
) => {
  if (fileCameraQuestion.response) {
    const files: ImageItem[] = []
    const promises: any[] = []
    const fileResults: any[] = [...fileCameraQuestion.response]
    fileResults.map((fileName: any, index: number) => {
      promises.push(
        getFileImage(fileName).then(response => {
          fileResults[index] = {
            ...response,
            url: response.url,
          }
          files.push({
            index,
            file: response.url,
            name: fileName,
          })
        }),
      )
      return fileName
    })
    await Promise.all(promises)
    return { ...fileCameraQuestion, response: fileResults, files }
  }
  return fileCameraQuestion
}

export const handleTransformImages = async (
  fileCameraQuestion: FileCameraQuestion,
) => {
  if (fileCameraQuestion.response) {
    const promises: any[] = []
    const fileResults: any[] = [...fileCameraQuestion.response]
    fileResults.map((file: any, index: number) => {
      return { index, name: file }
    })
    await Promise.all(promises)
    return { ...fileCameraQuestion, response: fileResults }
  }
  return fileCameraQuestion
}

export const handleSubmitTransform = async (dataItems: Item[]) => {
  const payloadItems = [...dataItems]
  for (let i = 0; i < payloadItems.length; i += 1) {
    const payloadItem = payloadItems[i]
    if (payloadItem.questionItem?.question.kind === 'fileCamera') {
      if (payloadItem.questionItem?.question.fileCameraQuestion) {
        payloadItem.questionItem.question.fileCameraQuestion =
          // eslint-disable-next-line no-await-in-loop
          await handleTransformImages(
            payloadItem.questionItem?.question.fileCameraQuestion,
          )
      }
    }
  }
  return payloadItems
}
