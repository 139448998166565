import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import { MeetingManagerJoinOptions } from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types'
import {
  useMeetingManager,
  useLocalVideo,
  useMeetingStatus,
  useAudioVideo,
  useLocalAudioOutput,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react'
import CryptoJS from 'crypto-js'
import { SECRET_PASSPHRASE, DeviceLabels } from 'app/config'
import { MeetingLocalVideo } from 'app/components/MeetingLocalVideo'
import { useAppState } from 'providers/AppStateProvider'
import { MeetingData } from 'types/MeetingData'
import { toast } from 'react-toastify'
import { RemoteMeetings } from 'app/containers/VideoCalling/components/RemoteMeetings'
import { StyleVideoPreviewContainer } from './Style'

export function DummyAttendee() {
  const { token } = useParams()
  const audioVideo: any = useAudioVideo()
  const meetingManager = useMeetingManager()
  const meetingStatus = useMeetingStatus()
  const { setAppMeetingInfo } = useAppState()
  const { isVideoEnabled, toggleVideo } = useLocalVideo()
  const { isAudioOn, toggleAudio } = useLocalAudioOutput()
  const { muted, toggleMute } = useToggleLocalMute()
  const [isFetchingMeetingInfo, setIsFetchingMeetingInfo] =
    useState<boolean>(false)
  const ciphertext: any = token
  const [isInitial, setIsInitial] = useState(false)

  useEffect(() => {
    if (audioVideo) {
      audioVideo.listAudioInputDevices().then((devices: any) => {
        if (devices.length > 0) {
          // eslint-disable-next-line
          console.log('devices', devices)
          // meetingManager.selectAudioInputDevice(devices[0].deviceId)
        }
      })
    }
  }, [audioVideo])

  const tryToConnectMeeting = async (meetingData: MeetingData) => {
    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      meetingData.meeting,
      meetingData.attendee,
    )
    const options: MeetingManagerJoinOptions = {
      deviceLabels: DeviceLabels.AudioAndVideo,
      enableWebAudio: true,
    }
    meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers =
      false
    // try {
    //   const constraints = { audio: true, video: true }
    //   await navigator.mediaDevices.getUserMedia(constraints)
    // } catch (error) {
    //   /* eslint-disable-next-line no-console */
    //   console.log('stream@error', error)
    // }
    await meetingManager.join(meetingSessionConfiguration, options)
    setAppMeetingInfo(meetingData.meeting, meetingData.attendee)
    await meetingManager.start()
    // await meetingManager.leave()
    // setIsJoining(true)
  }

  useEffect(() => {
    if (meetingStatus === 1) {
      if (isVideoEnabled) {
        toggleVideo()
      }
      if (isAudioOn) {
        toggleAudio()
      }
      if (!muted) {
        toggleMute()
      }
    }
  }, [meetingStatus])

  const fetchPreviewJoiningData = async () => {
    try {
      if (isFetchingMeetingInfo) {
        return
      }
      setIsFetchingMeetingInfo(true)
      const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_PASSPHRASE)
      const dataJoining = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      setIsFetchingMeetingInfo(false)
      /* eslint-disable-next-line no-console */
      await tryToConnectMeeting(dataJoining)
    } catch (error) {
      toast.error('Something went wrong')
      setIsFetchingMeetingInfo(false)
      /* eslint-disable-next-line no-console */
      console.error('fetch Preview error', error)
    }
  }

  const initialSetup = () => {
    // fetchPreviewJoiningData()
  }

  useEffect(() => {
    initialSetup()
    return () => {
      meetingManager?.leave()
      // stopBothVideoAndAudio(streamState)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const handleTurnOnCamera = () => {
    if (!isVideoEnabled) {
      toggleVideo()
      audioVideo?.startLocalVideoTile()
    } else {
      toggleVideo()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsInitial(true)
    }, 5000)
  }, [])

  useEffect(() => {
    if (isInitial) {
      fetchPreviewJoiningData()
    }
  }, [isInitial])

  return (
    <div className="dummy-wrapper-content">
      {isInitial && (
        <StyleVideoPreviewContainer>
          <MeetingLocalVideo nameplate="Hello Xinh" className="own-video" />
        </StyleVideoPreviewContainer>
      )}
      <div>
        <button type="button" onClick={handleTurnOnCamera}>
          Turn on {isVideoEnabled ? 'on' : 'off'}
        </button>
      </div>
      <StyleVideoPreviewContainer>
        <RemoteMeetings />
      </StyleVideoPreviewContainer>
    </div>
  )
}
