import { useAppDispatch } from 'app/hooks'
import { DefaultLayout } from 'app/layouts/DefaultLayout'
import { setTab } from 'features/switchTab/switchTabSlice'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import { PrivateRoute } from './app/guards/PrivateRoute'
import { routes } from './routes'
import './styles/app.css'

function App() {
  const { i18n } = useTranslation()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tabValue = searchParams.get('tab')
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (tabValue) {
      dispatch(setTab(tabValue as string))
    }
  }, [tabValue])

  const renderRoutes = (routeItems: any) => {
    const result = routeItems.map((route: any) => {
      const { path, element: Element, childrens, ...rest } = route
      const props = {
        ...rest,
        element: route.meta.middleware.includes('auth') ? (
          <PrivateRoute>
            <Element />
          </PrivateRoute>
        ) : (
          <Element />
        ),
      }
      const Layout = route.layout || DefaultLayout

      return (
        <Route key={path} element={<Layout />}>
          <Route path={path} {...props}>
            {childrens && renderRoutes(childrens)}
          </Route>
        </Route>
      )
    })

    return result
  }

  return (
    <>
      <Helmet
        titleTemplate="%s - HELPO-Telemedicine"
        defaultTitle="HELPO-Telemedicine"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="HELPO-Telemedicine" />
      </Helmet>
      <Routes>{renderRoutes(routes)}</Routes>
    </>
  )
}

export default App
