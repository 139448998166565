import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { shouldSubmitDisabled } from 'utils/helper'

type TypeTheme = 'confirm' | 'delete'
interface PopupConfirmProps {
  title: string
  open: boolean
  theme?: TypeTheme
  setOpen: (value: boolean) => void
  handleFunctionProps: any
  disabled?: boolean
}

function ThemeDelete({
  title,
  setOpen,
  handleFunctionProps,
  disabled,
}: {
  title: string
  setOpen: (value: boolean) => void
  handleFunctionProps: any
  disabled?: boolean
}) {
  const classBaseButton =
    'min-w-[144px] h-8 text-base font-semibold bg-blue-610 text-white px-4 rounded-full'

  return (
    <div className="py-14 px-12 flex flex-col justify-center items-center">
      <p className="mb-12 mx-[73px] text-lg leading-[27px] font-semibold text-gray-710">
        {title}
      </p>
      <div className="flex gap-12">
        <div className="basis-1/2 flex justify-center">
          <button
            type="button"
            className={classBaseButton}
            onClick={() => setOpen(false)}
          >
            いいえ
          </button>
        </div>
        <div className="basis-1/2 w-full flex justify-center">
          <button
            type="button"
            className={`${classBaseButton} ${shouldSubmitDisabled(disabled)}`}
            onClick={handleFunctionProps}
          >
            はい
          </button>
        </div>
      </div>
    </div>
  )
}

function ThemeConfirm({
  title,
  setOpen,
  handleFunctionProps,
}: {
  title: string
  setOpen: (value: boolean) => void
  handleFunctionProps: any
}) {
  const classBaseButton =
    'min-w-[144px] h-8 text-base font-semibold bg-blue-610 text-white px-4 rounded-full select-none'
  return (
    <div className="w-full text-center">
      <div className="py-14 px-12 flex flex-col justify-center">
        <h2 className="text-lg leading-[27px] font-semibold text-gray-710 whitespace-pre-line">
          {title}
        </h2>
        <div className="mt-12 w-full text-center space-x-12">
          <button
            type="button"
            className={classBaseButton}
            onClick={() => setOpen(false)}
          >
            いいえ
          </button>
          <button
            type="button"
            className={classBaseButton}
            onClick={handleFunctionProps}
          >
            はい
          </button>
        </div>
      </div>
    </div>
  )
}

export function PopupConfirm(props: PopupConfirmProps) {
  const { title, open, theme, setOpen, handleFunctionProps, disabled } = props

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => setOpen(true)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000ad] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-30 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {theme === 'confirm' ? (
                <Dialog.Panel className="relative bg-white text-left overflow-hidden shadow-xl transform transition-all max-w-[440px] w-full rounded-lg">
                  <ThemeConfirm
                    title={title}
                    setOpen={setOpen}
                    handleFunctionProps={handleFunctionProps}
                  />
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="relative bg-white text-left overflow-hidden shadow-xl transform transition-all max-w-[440px] w-full rounded-lg">
                  <ThemeDelete
                    title={title}
                    setOpen={setOpen}
                    handleFunctionProps={handleFunctionProps}
                    disabled={disabled}
                  />
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

PopupConfirm.defaultProps = {
  theme: 'confirm',
  disabled: false,
}

ThemeDelete.defaultProps = {
  disabled: false,
}
