/* This example requires Tailwind CSS v2.0+ */
/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowRightOnRectangleIcon,
  Bars4Icon,
  Cog8ToothIcon,
  HomeIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { LogoutModal } from 'app/components/LogoutModal'
import { tabs } from 'app/config/app'
import { ROUTES } from 'app/config/routes'
import { useAppSelector } from 'app/hooks'
import { videoCalling } from 'features/videoCalling/videoCallingSlice'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SidebarItem from './components/SidebarItem'

export default function Sidebar(props: {
  sidebarOpen: boolean | undefined
  isCollapsed: boolean
  toggleCollapse: (value: boolean) => void
  setSidebarOpen: (value: boolean) => void
}) {
  const { sidebarOpen, isCollapsed, toggleCollapse, setSidebarOpen } = props
  const { t } = useTranslation()
  const isVideoCalling = useAppSelector(videoCalling)
  const [isDialogLogout, setIsDialogLogout] = useState(false)

  const menuList = [
    {
      title: 'トップ',
      icon: (
        <HomeIcon
          className="flex-shrink-0 w-6 h-6 text-white mr-3"
          aria-hidden="true"
        />
      ),
      path: '/?tab=top',
      prefix: '/',
      childrens: [
        {
          title: '本日の診察',
          path: '/?tab=top',
        },
        {
          title: 'タスク',
          prefix: '/',
          childrens: [
            {
              title: '会計待ち',
              path: `/?tab=${tabs.TODAY_TASK}&sub_tab=${tabs.WAITING_ACCOUNTING}`,
            },
            {
              title: '発送待ち',
              path: `/?tab=${tabs.TODAY_TASK}&sub_tab=${tabs.WAITING_SHIPPING}`,
            },
          ],
        },
        {
          title: '配送先一覧',
          path: '/?tab=top_shipping_address',
        },
      ],
    },
    {
      title: 'スタッフ管理',
      path: ROUTES.STAFF.INDEX,
      icon: <img src="/doctor-1.svg" className="mr-3" alt="" />,
    },
    {
      title: '患者管理',
      path: ROUTES.PATIENTS.INDEX,
      icon: (
        <UserGroupIcon
          className="flex-shrink-0 w-6 h-6 text-white mr-3"
          aria-hidden="true"
        />
      ),
    },
    {
      title: '設定',
      icon: (
        <Cog8ToothIcon
          className="flex-shrink-0 w-6 h-6 text-white mr-3"
          aria-hidden="true"
        />
      ),
      path: `/settings?tab=${tabs.WORK_SCHEDULE}`,
      prefix: '/settings',
      childrens: [
        {
          title: '予約枠',
          path: `/settings?tab=${tabs.WORK_SCHEDULE}`,
        },
        {
          title: '病院設定',
          prefix: '/settings',
          childrens: [
            {
              title: '基本設定',
              path: `/settings?tab=${tabs.HOSPITAL_SETTING}&sub_tab=${tabs.BASIC_SETTINGS}`,
            },
            {
              title: 'メニュー設定',
              path: `/settings?tab=${tabs.HOSPITAL_SETTING}&sub_tab=${tabs.MENU_SETTINGS}`,
            },
            {
              title: '問診票設定',
              path: `/settings?tab=${tabs.HOSPITAL_SETTING}&sub_tab=${tabs.QUESTIONNAIRE_SETTING}`,
            },
            {
              title: '設定管理',
              path: `/settings?tab=${tabs.HOSPITAL_SETTING}&sub_tab=${tabs.MANAGEMENT_SETTING}`,
            },
          ],
        },
        {
          title: '薬局選択',
          path: `/settings?tab=${tabs.PHARMACY_SETTING}`,
        },
      ],
    },
  ]
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800 bg-gray">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex items-center flex-shrink-0 px-4">
                  <img
                    className="w-10 h-10 rounded-full"
                    src="/logo-sidebar.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="flex-1 h-0 mt-5 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {/* <Menu collapsed={isCollapsed} /> */}
                    menu ne
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={`${
          isVideoCalling ? 'pointer-events-none' : ''
        } hidden md:flex md:w-16 md:flex-col fixed left-0 top-0 h-screen z-[11] ${
          isCollapsed ? 'md:w-16 md:min-w-16' : 'md:w-64 md:min-w-64'
        }`}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-1 min-h-0 bg-gray-800">
          <div
            className={`flex items-center flex-shrink-0 overflow-hidden bg-gray-710 pb-2  ${
              isCollapsed ? 'px-0 pt-[7px]' : 'pt-[9px] px-[9.5px]'
            }`}
          >
            {isCollapsed ? (
              <img
                className="flex-shrink-0 mx-auto overflow-hidden bg-white rounded-full h-[45px] w-[45px] max-w-none"
                src="/logo-sidebar.svg"
                alt="Workflow"
              />
            ) : (
              <img
                className="relative top-[-2px] h-[45px] w-[45px]"
                src="/logo-sidebar.svg"
                alt="Workflow"
              />
            )}
          </div>
          <div className="flex flex-col flex-1 overflow-y-auto bg-gray">
            <nav className="flex-1 px-3 py-4">
              <div
                className={`flex mb-3 ${
                  isCollapsed ? 'justify-center' : 'justify-end'
                }`}
              >
                <button
                  type="button"
                  className="px-4 text-white focus:outline-none group h-9"
                  onClick={() => toggleCollapse(!isCollapsed)}
                >
                  <Bars4Icon
                    className="w-8 h-8 hamburger-default group-hover:hidden"
                    aria-hidden="true"
                  />
                  {isCollapsed ? (
                    <ArrowRightIcon
                      className="hidden w-8 h-8 hamburger-default group-hover:inline"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowLeftIcon
                      className="hidden w-8 h-8 hamburger-default group-hover:inline"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
              <div className={`${isCollapsed ? 'sidebar-collapsed' : ''}`}>
                {menuList.map((item, index) => (
                  <SidebarItem
                    key={index}
                    item={item}
                    collapsed={isCollapsed}
                  />
                ))}
                <div
                  className="p-2 flex cursor-pointer sidebar-link"
                  onClick={() => setIsDialogLogout(true)}
                >
                  <ArrowRightOnRectangleIcon
                    className="flex-shrink-0 w-6 h-6 text-white-400 text-white mr-3"
                    aria-hidden="true"
                  />
                  <span className="text-white text-lg leading-[27px] link-title">
                    {t('common.logOut')}
                  </span>
                </div>
              </div>
              <LogoutModal open={isDialogLogout} setOpen={setIsDialogLogout} />
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
