import { ARTIST_PREFIX } from 'app/config'
import { MEETING_APP } from 'app/config/meeting'

export const resolveAvailableDevices = (devices: any[]) =>
  devices.filter(device => device.deviceId !== 'none')

export const resolveAttendeeName = (attendee: any) => {
  const name = attendee.ExternalUserId || attendee.externalUserId
  return name ? decodeURIComponent(name) : ''
}

export function resolveAttendeeNameString(attendeeName: string) {
  let name = decodeURIComponent(attendeeName)
  const nameArray = name.split('-')
  if (nameArray.length) {
    const uuid = nameArray.slice(-1).pop()
    if (uuid?.length === 8) {
      name = name.replace(`-${uuid}`, '')
    }
  }

  return name.replace(ARTIST_PREFIX, '')
}

export function resolveShortAttendeeName(attendeeName: string) {
  if (!attendeeName) {
    return ''
  }
  return resolveAttendeeNameString(attendeeName)
}

export function parseAttendeeName(attendeeName: string) {
  const uuid = new Date().getTime().toString(36)
  return `${attendeeName}-${uuid}`
}

export const parseAudienceFromRoster = (roster: any, excludeIds: string[]) =>
  Object.values(roster).filter(
    (attendee: any) =>
      [MEETING_APP.DUMMY_DOCTOR].indexOf(attendee.externalUserId) === -1 &&
      excludeIds.indexOf(attendee.externalUserId) === -1 &&
      excludeIds.indexOf(attendee.AttendeeId) === -1,
  )

export const findTileIdByAttendee = (
  attendee: any,
  tileIdToAttendeeId: any,
) => {
  const tileIdToAttendeeIdKeys = Object.keys(tileIdToAttendeeId)
  const tileId: any = tileIdToAttendeeIdKeys.find(
    tileIdToAttendeeIdKey =>
      tileIdToAttendeeId[tileIdToAttendeeIdKey] === attendee.chimeAttendeeId,
  )
  return parseInt(tileId, 10)
}

export const arrangeAttendees = (attendeesItems: any[]) => {
  // const artistItem = attendeesItems.find(attendee =>
  //   isArtistName(attendee.externalUserId)
  // )

  // const attendeesWithoutArtist = attendeesItems.filter(
  //   attendee => !isArtistName(attendee.externalUserId)
  // )
  // return artistItem
  //   ? [artistItem, ...attendeesWithoutArtist]
  //   : attendeesWithoutArtist
  return attendeesItems
}
