import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

interface EventJoined {
  medicalRecordId: string
  id: number
}
interface VideoCalling {
  isCalling: boolean
  eventJoined?: EventJoined
}

const initialState: VideoCalling = {
  isCalling: false,
  eventJoined: undefined,
}

export const videoCallingSlice = createSlice({
  name: 'video_calling',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setIsVideoCalling: (state, action: PayloadAction<boolean>) => {
      state.isCalling = action.payload
    },
    setEventJoined: (state, action: PayloadAction<EventJoined>) => {
      state.eventJoined = action.payload
    },
  },
})

export const { setIsVideoCalling, setEventJoined } = videoCallingSlice.actions

export const videoCalling = (state: RootState) => state.video_calling.isCalling

export default videoCallingSlice.reducer
