import { QuestionnaireResponse } from 'app/containers/QuestionerTemplate/types'
import { QuestionerSubmission } from 'app/containers/QuestionerTemplate/QuestionerSubmission'

export function QuestionerResponseItem({
  questionnaireResponse,
}: {
  questionnaireResponse: QuestionnaireResponse
}) {
  return (
    <div className="">
      <QuestionerSubmission
        id={questionnaireResponse.id}
        formItems={questionnaireResponse.items}
      />
    </div>
  )
}
