import { tabs } from 'app/config/app'
import useChangeTab from 'hooks/useChangeTab'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface SidebarItemProps {
  collapsed: boolean
  item: {
    childrens?: any[]
    icon?: any
    title: string
    path?: string
    prefix?: string
  }
}

export default function SidebarItem({ item, collapsed }: SidebarItemProps) {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const { pathname, search } = location
  const handleMultipleChangeTab = useChangeTab()
  const homeTab: string = `/?tab=${tabs.TOP}`

  useEffect(() => {
    if (collapsed) return setOpen(false)
  }, [collapsed])

  const handleClickCollapsedMenu = (url: string) => {
    if (url === homeTab) {
      handleMultipleChangeTab(homeTab)
    } else {
      handleMultipleChangeTab(`${`/settings?tab=${tabs.WORK_SCHEDULE}`}`)
    }
  }

  if (item.childrens) {
    return (
      <div
        onClick={
          collapsed
            ? () => handleClickCollapsedMenu(item?.path as string)
            : () => {}
        }
        className={`sidebar-item cursor-pointer ${open ? 'open' : ''}  ${
          (item.prefix === pathname && collapsed) ||
          (item.prefix === pathname && !open && !collapsed)
            ? 'active'
            : ''
        }`}
      >
        <div
          className="sidebar-title"
          onClick={!collapsed ? () => setOpen(!open) : () => {}}
        >
          <p className="flex items-center flex-grow">
            {item?.path && collapsed ? (
              <button
                type="button"
                onClick={() => handleMultipleChangeTab(item.path as string)}
              >
                {item.icon && item.icon}
              </button>
            ) : (
              item.icon && item.icon
            )}

            <span className="text-white text-lg leading-[27px] link-title">
              {item.title}
            </span>
          </p>
          {!collapsed && (
            <span onClick={() => setOpen(!open)}>
              <img
                src="/icon-arrow-down.svg"
                className="toggle-btn bi-chevron-down p-1"
                alt=""
              />
            </span>
          )}
        </div>
        {!collapsed && (
          <div className="sidebar-content">
            {item.childrens.map((child, index) => (
              <SidebarItem collapsed={collapsed} key={index} item={child} />
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <button
      type="button"
      onClick={() => handleMultipleChangeTab(item.path as string)}
      className={`p-2 flex sidebar-link w-full ${
        item.path === pathname + search ? 'active' : ''
      }`}
    >
      {item.icon && (
        <div
          onClick={() => handleMultipleChangeTab(item.path as string)}
          className="text-white text-lg leading-[27px]"
        >
          {item.icon && item.icon}
        </div>
      )}

      <div
        onClick={() => handleMultipleChangeTab(item.path as string)}
        className={`text-white link-title w-full block text-left ${
          item.icon ? 'text-lg leading-[27px]' : 'text-base leading-6'
        }`}
      >
        {item.title}
      </div>
    </button>
  )
}
