import { useState } from 'react'
// import { useNavigate, Outlet } from 'react-router-dom'
import { MemorizedSocketListener } from 'app/containers/SocketListener'
import { useAppSelector } from 'app/hooks'
import { selectCurrentUser } from 'features/auth/authSlice'
import { Outlet } from 'react-router-dom'
// import { ROUTES } from 'app/config/routes'
// import { AUTHENTICATE_TOKEN_KEY } from 'app/config'
import NotifyNewMedicalExamination from 'app/containers/Home/components/TodayMedicalTreatment/component/NotifyNewMedicalExamination'
import Header from '../partials/Header'
import Sidebar from '../partials/Sidebar'

export function DefaultLayout() {
  const currentUser = useAppSelector(selectCurrentUser)
  // const navigate = useNavigate()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  // const isLoggedIn = localStorage.getItem(AUTHENTICATE_TOKEN_KEY)
  const [hasCheckedAuth] = useState<boolean>(true)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const toggleCollapse = (value: boolean) => {
    setIsCollapsed(value)
  }

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     return navigate(ROUTES.AUTH.LOGIN)
  //   }
  //   setHasCheckedAuth(true)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return !hasCheckedAuth ? (
    <section className="flex items-center justify-center h-screen">
      Loading
    </section>
  ) : (
    <div className="flex max-w-[100vw] min-h-1024px shrink-0">
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
      />
      <div className={`flex flex-col grow ${isCollapsed ? 'pl-16' : 'pl-64'}`}>
        <Header setSidebarOpen={setSidebarOpen} isCollapsed={isCollapsed} />
        {currentUser?.accountId && (
          <MemorizedSocketListener token={currentUser.accountId} />
        )}
        <main className="flex-1 bg-[#FFFFFF] pc:p-6 p-8 overflow-hidden">
          <Outlet />
          <NotifyNewMedicalExamination />
        </main>
      </div>
    </div>
  )
}
