export function TableRowsLoading(props: { rowsNum?: number; colsNum: number }) {
  const { rowsNum, colsNum } = props

  return (
    <>
      {Array.from(Array(rowsNum), (rowVal: any, rowIndex: number) => (
        <tr
          className="text-left divide-x divide-gray-200 animate-pulse"
          key={rowIndex}
        >
          {Array.from(Array(colsNum), (colVal: any, colIndex: number) => (
            <td
              className="whitespace-nowrap px-3 py-4 text-[15px] text-[#333]"
              key={colIndex}
            >
              <label className="inline-block w-full h-10 rounded-lg bg-slate-200" />
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

TableRowsLoading.defaultProps = {
  rowsNum: 15,
}
