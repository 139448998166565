export const APP_NAME = process.env.REACT_APP_NAME
export const API_HOST = process.env.REACT_APP_API_URL
export const API_HOST_V1 = process.env.REACT_APP_API_URL_V1
export const APP_HTTP_CLIENT_TYPE = process.env.REACT_APP_HTTP_CLIENT_TYPE
export const APP_WS_URL = process.env.REACT_APP_WS_URL
export const APP_URL = process.env.REACT_APP_URL

export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const APP_ENV = process.env.REACT_APP_ENV

export const SECRET_PASSPHRASE = 'xpSqz9jT71Umq8xL'

export const AUTHENTICATE_TOKEN_KEY = 'helpo.auth.accessToken'
export const AUTHENTICATE_USER_KEY = 'helpo.auth.currentUser'

export const AUTHENTICATE_INFO = [AUTHENTICATE_TOKEN_KEY, AUTHENTICATE_USER_KEY]

// eslint-disable-next-line no-shadow
export enum DeviceLabels {
  None,
  Audio,
  Video,
  AudioAndVideo,
}

export const VIDEO_INPUT_QUALITY = {
  '360p': '360p (nHD) @ 15 fps (600 Kbps max)',
  '540p': '540p (qHD) @ 15 fps (1.4 Mbps max)',
  '720p': '720p (HD) @ 15 fps (1.4 Mbps max)',
}

export const PATIENT_TABS: any = {
  PROFILE: 'profile',
  CONSULTATION: 'consultation',
  ACCOUNTING: 'accounting',
}

export const DAY_OF_WEEKS = ['日', '月', '火', '水', '木', '金', '土']

export const ADMIN = 'admin'
export const DIRECTOR = 'director'
export const AUDIENCE = 'audience'
export const ARTIST = 'artist'
export const ARTIST_PREFIX = 'artist_'

export const MEETING_LAYOUT = {
  videoContainerWidth: 250,
  videoContainerWidthMobile: 175,
  videoContainerHeight: 140,
  videoContainerHeightMobile: 75,
  videoHeaderHeight: 40,
  videoHeaderHeightMobile: 35,
  radius: 0,
}

export const MEETING_STATES = {
  PENDING: 'PENDING',
  JOINING: 'JOINING',
  JOINED: 'JOINED',
}

export const ROLE_PATIENT = 'PATIENT'

export const TIME_ZONE_JP = 'Asia/Tokyo'

export const MINUTE_PER_SLOT: any = {
  15: [1, 1, 1, 1],
  10: [2, 1, 2, 1],
  7.5: [2, 2, 2, 2],
  6: [3, 2, 3, 2],
  5: [3, 3, 3, 3],
}
