import React from 'react'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  loading?: boolean
  type?: 'submit' | 'reset' | 'button' | undefined
  bgButton?: string
  className?: string
  disabled?: boolean
}

export function Button(props: ButtonProps) {
  const {
    children,
    loading,
    type,
    bgButton,
    color,
    className,
    disabled,
    ...rest
  } = props
  const buttonClass =
    'w-full flex justify-center px-[56px] border border-transparent shadow-sm text-[16px] items-center font-semibold text-white rounded-full mx-auto whitespace-nowrap'
  const disableClass = disabled
    ? '!bg-[#9CA3AF] !text-white !cursor-not-allowed'
    : ''
  // eslint-disable-next-line react/button-has-type
  return (
    <button
      className={`${buttonClass} ${className} ${
        color ? 'bg-blue-610' : bgButton
      } ${
        loading ? 'bg-slate-600 cursor-not-allowed pointer-events-none' : ''
      } ${disableClass}`}
      disabled={loading || disabled}
      /* eslint-disable react/button-has-type */
      type={type}
      {...rest}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  loading: false,
  type: 'button',
  bgButton: 'bg-slate-900',
  className: '',
  disabled: false,
}
