import React, { useEffect, useState } from 'react'
import {
  RemoteVideo,
  useAttendeeStatus,
  useActiveSpeakersState,
  // useFeaturedTileState,
  // useContentShareState,
} from 'amazon-chime-sdk-component-library-react'
import { resolveShortAttendeeName, resolveAttendeeName } from 'utils/meeting'
import { Camera } from 'app/components/Meeting/ui/icons/Camera'
import { VideoOffCameraMain, RemoteVideoPlaceholder } from './Style'

export interface Props {
  attendee: any
  isHightlight?: boolean
}

const DELAY_UNACTIVE = 1800
const IS_TEST_UI = false

function NoVideo() {
  return IS_TEST_UI ? (
    <video
      controls
      loop
      autoPlay
      muted
      className="ch-video absolute inset-0 w-full h-full"
    >
      <source
        src="https://www.w3schools.com/html/mov_bbb.mp4"
        type="video/mp4"
      />
      <source
        src="https://www.w3schools.com/html/mov_bbb.mp4"
        type="video/ogg"
      />
      Your browser does not support the video tag.
    </video>
  ) : (
    <RemoteVideoPlaceholder className="remote-video-placeholder" />
  )
}

export function MeetingRemoteVideo({ attendee }: Props) {
  const resolveAttendeeId = () => attendee.AttendeeId || attendee.attendeeId

  const { muted, videoEnabled } = useAttendeeStatus(resolveAttendeeId())
  // const { tileId: featuredTileId } = useFeaturedTileState()
  // const { tileId: contentTileId } = useContentShareState()
  const activeSpeakers = useActiveSpeakersState()
  const [isSpeaking, setIsSpeaking] = useState(false)
  const [wsTimer, setWsTimer] = useState<any>(null)

  const handleDelayTurnOffActive = () => {
    setIsSpeaking(false)
  }

  useEffect(() => {
    if (activeSpeakers.indexOf(resolveAttendeeId()) !== -1) {
      setIsSpeaking(true)
      if (wsTimer) {
        clearTimeout(wsTimer)
      }
    } else {
      setWsTimer(setTimeout(handleDelayTurnOffActive, DELAY_UNACTIVE))
    }
  }, [activeSpeakers])

  // const featured = !contentTileId && featuredTileId === attendee.tileId
  const getVideoClass = () =>
    isSpeaking && !muted ? 'x-remote-video ch-featured-tile' : 'x-remote-video'

  return attendee.tileId ? (
    videoEnabled ? (
      <RemoteVideo
        tileId={attendee.tileId}
        name={resolveShortAttendeeName(resolveAttendeeName(attendee))}
        className={getVideoClass()}
      />
    ) : (
      <VideoOffCameraMain className="remote-video-off">
        <Camera width="5rem" disabled type="svg" />{' '}
      </VideoOffCameraMain>
    )
  ) : (
    <NoVideo />
  )
}

MeetingRemoteVideo.defaultProps = {
  isHightlight: false,
}
export const MemoMeetingRemoteVideo = React.memo(MeetingRemoteVideo)
