import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import tabReducerSlice from 'features/tabReducer/tabReducerSlice'
import authReducer from '../features/auth/authSlice'
import commonReducer from '../features/common/commonSlice'
import counterReducer from '../features/counter/counterSlice'
import lastRunTimeReducer from '../features/lastRunTime/lastRunTimeSlice'
import switchTabReducer from '../features/switchTab/switchTabSlice'
import telephoneRangReducer from '../features/telephoneRang/telephoneRangSlice'
import videoCallingReducer from '../features/videoCalling/videoCallingSlice'
import imageRotateReducer from '../features/imageRotate/imageRotateSlice'
import patientPageReducer from '../features/patientPage/patientSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    switch_tab: switchTabReducer,
    auth: authReducer,
    common: commonReducer,
    video_calling: videoCallingReducer,
    telephone_rang: telephoneRangReducer,
    tab: tabReducerSlice,
    time: lastRunTimeReducer,
    imageRotate: imageRotateReducer,
    patientPage: patientPageReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
