import React, { ReactNode } from 'react'
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react'
import { meetingConfigValue } from 'app/config/meeting'
import { useAppState } from '../AppStateProvider'
import { MeetingProviderWrapperWithVF } from './MeetingProviderWrapperWithVF'

type Props = {
  children: ReactNode
}

export function MeetingProviderWrapper({ children }: Props) {
  const { isWebAudioEnabled } = useAppState()

  const enableWebAudioEnabled = !true
  if (isWebAudioEnabled && enableWebAudioEnabled) {
    return (
      <MeetingProviderWrapperWithVF>{children}</MeetingProviderWrapperWithVF>
    )
  }
  return <MeetingProvider {...meetingConfigValue}>{children}</MeetingProvider>
}

export default MeetingProviderWrapper
