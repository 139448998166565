import { APP_WS_URL } from 'app/config'

interface Payload {
  roomId?: string
  token?: string
}

export function getSocketConnectUrl(payload: Payload) {
  const { token } = payload
  const tokenField = 'accountId'
  const wsUrl = token ? `${APP_WS_URL}?${tokenField}=${token}` : `${APP_WS_URL}`
  return wsUrl
}
