import qs from 'qs'
import { Task } from 'types/Task'
import { selectFieldResolver } from 'app/config/select-fields'
import { apiClient } from './api'

export const getTaskToday = async (status: String[]) => {
  const dataApiGetTaskToday: any = await apiClient.get<Task[]>(
    '/api/v1/tasks',
    {
      params: {
        status,
        selectFields: selectFieldResolver.getTaskToday(),
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    },
  )
  return dataApiGetTaskToday
}

export const getTopTask = async (params: any) => {
  const dataApiGetTaskToday: any = await apiClient.get<any>(
    '/api/v1/tasks/top',
    {
      params,
    },
  )
  return dataApiGetTaskToday
}

export const getTodaysMedicalExamination = async (
  section: String[],
  date: string,
  status?: string,
) => {
  const dataApiGetTodaysMedicalExamination: any = await apiClient.get<Task[]>(
    '/api/v1/tasks',
    {
      params: {
        section,
        date,
        status,
        selectFields: selectFieldResolver.getTodaysMedicalExamination(),
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    },
  )
  return dataApiGetTodaysMedicalExamination
}

export const getTodaysMedicalExaminations = async (date: string) => {
  const dataApiGetTodaysMedicalExamination: any = await apiClient.get<Task[]>(
    '/api/v1/tasks',
    {
      params: {
        date,
        selectFields: selectFieldResolver.getTodaysMedicalExaminations(),
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    },
  )
  return dataApiGetTodaysMedicalExamination
}

export const getShippingAddress = async (date: string, status: string[]) => {
  const dataApiGetShippingAddress: any = await apiClient.get<Task[]>(
    '/api/v1/tasks',
    {
      params: {
        date,
        status,
        selectFields: selectFieldResolver.getShippingAddress(),
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    },
  )
  return dataApiGetShippingAddress
}
