import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

const initialState: any = {
  value: 'top',
}

export const switchTabSlice = createSlice({
  name: 'switch_tab',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTab: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
  },
})

export const { setTab } = switchTabSlice.actions

export const selectTab = (state: RootState) => state.switch_tab.value

export default switchTabSlice.reducer
