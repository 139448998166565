import { useEffect, useRef } from 'react'

export type TypeFunctionEvent = (event: globalThis.KeyboardEvent) => void

export const useKeypress = (handler: TypeFunctionEvent) => {
  const callback = useRef<TypeFunctionEvent>()

  callback.current = handler

  useEffect(() => {
    const eventListener = (event: globalThis.KeyboardEvent): void => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      callback.current && callback.current(event)
    }

    window.addEventListener('keydown', eventListener)
    return () => {
      window.removeEventListener('keydown', eventListener)
    }
  }, [])
}
