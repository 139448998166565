import { table } from '../../config/table'

export function TableHeading() {
  return (
    <thead className="bg-gray-80">
      <tr className="text-left divide-x divide-gray-200">
        {table.columns.index.active && (
          <th
            scope="col"
            className="text-[15px] text-[#333] sm:pl-6 w-10 overflow-hidden"
          >
            No
          </th>
        )}
        <th
          scope="col"
          className="text-[15px] text-[#333] sm:pl-6 w-96 overflow-hidden"
        >
          Template name
        </th>
        <th scope="col" className="text-[15px] text-[#333] text-center">
          Patient
        </th>
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-[15px] text-[#333]"
        >
          Submitted at
        </th>
        <th
          scope="col"
          className="text-[15px] text-[#333] stop-stretching text-center"
        >
          Actions
        </th>
      </tr>
    </thead>
  )
}
