import { DepartmentsSetting } from 'types/Clinic'
import { Department } from 'types/Department'

export const sortOrderDepartmentByCategory = (
  department: DepartmentsSetting[],
) => {
  return department.sort((p1, p2) =>
    p1.category < p2.category ? 1 : p1.category > p2.category ? -1 : 0,
  )
}

export const sortOrderDepartmentByCategoryAndPriority = (
  department: DepartmentsSetting[],
) => {
  return department.sort((a: any, b: any) => {
    if (a.category === b.category) {
      if (a.category === 'MEDICINE') {
        // If both are medicines, sort by isDisplayOnTop
        return b.isDisplayOnTop - a.isDisplayOnTop
      }
      return 0
    }
    // Sort by category
    return b.category.localeCompare(a.category)
  })
}

export const handleDepartmentSettings = (
  sections: DepartmentsSetting[],
  medicines: DepartmentsSetting[],
) => {
  const sectionsSettings: DepartmentsSetting[] = sections.map(
    (department, index) => {
      const departmentSetting: DepartmentsSetting = {
        ...department,
        sortOrder: index + 1,
      }
      delete departmentSetting.iconUrl
      return departmentSetting
    },
  )
  const medicinesSettings: DepartmentsSetting[] = medicines.map(
    (department, index) => {
      const departmentSetting: DepartmentsSetting = {
        ...department,
        sortOrder: index + 1,
      }
      delete departmentSetting.iconUrl
      return departmentSetting
    },
  )
  return [...sectionsSettings, ...medicinesSettings]
}

export const sortDepartments = (departments: Department[]) => {
  // Sort array by category
  departments.sort((p1, p2) =>
    p1.category < p2.category ? 1 : p1.category > p2.category ? -1 : 0,
  )
  // After sorting by category, further sort by sortOrder if same category
  departments.sort((a, b) => {
    if (a.category === b.category) {
      return a.sortOrder - b.sortOrder
    }
    return 0
  })
  return departments
}
