import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { tabs } from 'app/config/app'
import { FileCameraQuestion } from 'app/containers/QuestionerTemplate/types'
import { RootState } from 'app/store'
import { updateRecords } from 'utils/helper'

interface TabReducerState {
  isModified: boolean
  isShowPopupGlobal: boolean
  subTabChoice: string
  pathChoice: string
  listImageQuestionnaire: InfoImageQuestionnaire[]
}

export type InfoImageQuestionnaire = {
  key: string
  itemId: string
  value: FileCameraQuestion
}

const initialState: TabReducerState = {
  isModified: false,
  isShowPopupGlobal: false,
  subTabChoice: tabs.BASIC_SETTINGS,
  pathChoice: '',
  listImageQuestionnaire: [],
}

export const tabReducerSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setIsModified: (state, action: PayloadAction<boolean>) => {
      state.isModified = action.payload
    },
    setShowPopupGlobal: (state, action: PayloadAction<boolean>) => {
      state.isShowPopupGlobal = action.payload
    },
    setSubTabChoice: (state, action: PayloadAction<string>) => {
      state.subTabChoice = action.payload
    },
    setPathChoice: (state, action: PayloadAction<string>) => {
      state.pathChoice = action.payload
    },
    setListImageQuestionnaire: (
      state,
      action: PayloadAction<InfoImageQuestionnaire>,
    ) => {
      const newRecords: InfoImageQuestionnaire[] = [action.payload]

      state.listImageQuestionnaire = updateRecords(
        state.listImageQuestionnaire,
        newRecords,
        (a, b) => a.key === b.key && a.itemId === b.itemId,
      )
    },
  },
})

export const {
  setIsModified,
  setShowPopupGlobal,
  setSubTabChoice,
  setPathChoice,
  setListImageQuestionnaire,
} = tabReducerSlice.actions

export const selectIsModified = (state: RootState) => state.tab.isModified
export const selectIsShowPopupGlobal = (state: RootState) =>
  state.tab.isShowPopupGlobal
export const selectSubTabChoice = (state: RootState) => state.tab.subTabChoice
export const selectPathChoice = (state: RootState) => state.tab.pathChoice
export const selectListImageQuestionnaire = (state: RootState) =>
  state.tab.listImageQuestionnaire

export default tabReducerSlice.reducer
